import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {EventTypeLegacyEntity} from '../entities/event-type-legacy/event-type.legacy.entity';
import {LocaleEventTypeEntity} from '../entities/event-type-legacy/locale-event-type.entity';
import {LocalizedEventTypeEntity} from '../entities/event-type-legacy/localized-event-type.entity';
import {MetadataEventTypeEntity} from '../entities/event-type-legacy/metadata-event-type.entity';
import {LocaleEntityType} from '../entities/locale-entity.type';
import {ImageHelper} from '../helpers/image.helper';
import {NewsroomHelper} from '../helpers/newsroom.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';

@Injectable({providedIn: 'root'})
export class EventTypeLegacyRepository extends AbstractRepository {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'eventTypes');
  }

  public async saveEventType(
    metadataEventType: MetadataEventTypeEntity,
    localizedDataArray: LocaleEventTypeEntity[],
  ): Promise<DocumentReference> {
    const eventTypesCollection = await this.angularFirestore.collection(this.collectionName);
    const i18nCollection = await this.angularFirestore.collection('i18n');

    return eventTypesCollection
      .add({
        logo: metadataEventType.logo,
        newsroom: metadataEventType.newsroom,
        icon: metadataEventType.icon,
      })
      .then((documentReference: DocumentReference) => {
        for (const localizedData of localizedDataArray) {
          i18nCollection.doc(localizedData.locale).collection(this.collectionName).doc(documentReference.id).set({
            eventType: documentReference.path,
            name: localizedData.name,
            description: localizedData.description,
            website: localizedData.website,
          });
        }

        return documentReference;
      });
  }

  public getEventTypesByLocale(locale: Locale = Locale.DE): Observable<LocalizedEventTypeEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(this.angularFirestore.collection(this.collectionName)),
      this.collectionName,
      'eventType',
      locale,
    ).pipe(
      map((eventTypes) =>
        eventTypes
          .map((eventType) => this.createEntityFromResponseWithLocale(eventType))
          .sort((eventTypeA: LocalizedEventTypeEntity, eventTypeB: LocalizedEventTypeEntity) => {
            if (eventTypeA.name && eventTypeB.name) {
              return eventTypeA.name.localeCompare(eventTypeB.name);
            }
            return eventTypeA.id.localeCompare(eventTypeB.id);
          }),
      ),
    );
  }

  public getEventTypeByKeyWithLocalizedContent(key: string, locale: Locale = Locale.DE): Observable<LocalizedEventTypeEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'eventType',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  public getEventTypeByKeyWithLocalizedContents(key: string, locales: any[]): Observable<EventTypeLegacyEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'eventType',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  public async updateEventType(eventTypeEntity: EventTypeLegacyEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, eventTypeEntity.getLocalizedContentEntity(localeKey).id, {
        name: eventTypeEntity.getName(localeKey),
        description: eventTypeEntity.getDescription(localeKey),
        website: eventTypeEntity.getWebsite(),
      });
    }

    return this.updateDocument(eventTypeEntity.getId(), {
      logo: eventTypeEntity.getLogo(),
      newsroom: eventTypeEntity.getNewsroom(),
      icon: eventTypeEntity.getIcon(),
      updatedAt: new Date(),
    });
  }

  public createEntityFromResponseWithLocales(response): EventTypeLegacyEntity {
    const localizedEntities = {} as LocaleEntityType<LocaleEventTypeEntity>;

    for (const key of Object.keys(response.localized)) {
      localizedEntities[key] = new LocaleEventTypeEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].name,
        response.localized[key].description,
        response.localized[key].website,
      );
    }

    const metadataEventTypeEntity = new MetadataEventTypeEntity(
      response.logo || ImageHelper.init(),
      response.newsroom || NewsroomHelper.init(),
      response.icon || ImageHelper.init(),
    );

    return new EventTypeLegacyEntity(response.id, metadataEventTypeEntity, localizedEntities);
  }

  private createEntityFromResponseWithLocale(document: any, locale: Locale = Locale.DE): LocalizedEventTypeEntity {
    const localizedContent = new LocaleEventTypeEntity(document.id, locale, document.name, document.description, document.website);

    const metadataEventTypeEntity = new MetadataEventTypeEntity(
      document.logo || ImageHelper.init(),
      document.newsroom || NewsroomHelper.init(),
      document.icon || ImageHelper.init(),
    );

    return new LocalizedEventTypeEntity(document.id, metadataEventTypeEntity, localizedContent);
  }
}
