import {AngularFirestore, DocumentData, DocumentReference, QueryFn} from '@angular/fire/compat/firestore';
import {iif, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {Entity} from '../entities/entity.interface';
import {AbstractRepository} from './abstract.repository';

export abstract class AbstractSimpleEntityRepository<
  ENTITY extends Entity<ENTITY, DOC>,
  DOC = DocumentData,
> extends AbstractRepository<DOC> {
  protected constructor(angularFirestore: AngularFirestore, collectionName: string, protected entityPrototype: ENTITY) {
    super(angularFirestore, collectionName);
  }

  public add(entity: ENTITY): Promise<DocumentReference<DOC>> {
    return this.angularFirestore.collection<DOC>(this.collectionName).add(entity.getDocumentFields());
  }

  public update(entity: ENTITY): Promise<void> {
    return this.updateDocument(entity.id, entity.getDocumentFields());
  }

  public delete(entityId: string): Promise<void> {
    return this.deleteDocument(this.collectionName, entityId);
  }

  public get(entityId: string): Observable<ENTITY> {
    return this.getDocumentAsObservable(this.angularFirestore.collection<DOC>(this.collectionName).doc(entityId)).pipe(
      map((document) => this.entityPrototype.fromDocument(document)),
    );
  }

  public getOnce(entityId: string): Observable<ENTITY> {
    return this.angularFirestore
      .collection<DOC>(this.collectionName)
      .doc(entityId)
      .get()
      .pipe(
        switchMap((documentSnapshot) =>
          iif(
            () => documentSnapshot.exists,
            of(this.entityPrototype.fromDocument({id: documentSnapshot.id, ref: documentSnapshot.ref, ...documentSnapshot.data()})),
            of(null),
          ),
        ),
      );
  }

  public getAll(query?: QueryFn): Observable<ENTITY[]> {
    return this.getDocumentsAsObservable(this.angularFirestore.collection<DOC>(this.collectionName, query)).pipe(
      map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))),
    );
  }

  public getAllBySnapshotChanges(query?: QueryFn, allowValuesFromCache?: boolean): Observable<ENTITY[]> {
    return this.getDocumentsBySnapshotChanges(this.angularFirestore.collection<DOC>(this.collectionName, query), allowValuesFromCache).pipe(
      map((documents) => documents.map((document) => this.entityPrototype.fromDocument(document))),
    );
  }
}
