import {EventDocumentI18n} from '../entities/event/event-document.interface';
import {EventAward, LocaleEventAward} from '../interfaces/event-award.interface';
import {Image} from '../interfaces/image.interface';
import {ImageHelper} from './image.helper';

export class EventHelper {
  public static initMetadataAward(image: Image = ImageHelper.init()): EventAward {
    return {image};
  }

  public static initLocaleEventAward({iconLabel, description} = {iconLabel: '', description: ''}): LocaleEventAward {
    return {iconLabel, description};
  }

  public static initI18n({
    name = '',
    location = '',
    description = '',
    descriptionMarkdown = '',
    language = '',
    participants = '',
    price = '',
    registrationLabel = '',
    registrationLink = '',
    speakers = '',
    additionalLink = '',
    additionalLabel = '',
    locationLink = '',
    award = EventHelper.initLocaleEventAward(),
  } = {}): EventDocumentI18n {
    return {
      name,
      location,
      description,
      descriptionMarkdown,
      languages: language,
      participants,
      price,
      registrationLabel,
      registrationLink,
      speakers,
      additionalLink,
      additionalLabel,
      locationLink,
      award,
    };
  }
}
