import {O} from 'ts-toolbelt';

import {Locale} from '../../interfaces/environment.interface';
import {FirestoreTimestampType} from '../../interfaces/firebase';
import {FormControlType} from '../../interfaces/form.interface';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {Dates} from '../dates';
import {UserRatingDocumentMeta} from '../user-rating/user-rating-document.interface';
import {UserFavorites} from './user-favorites.interface';
import {UserVotings} from './user-votings.interface';

export type UserDocument = UserInterface<FirestoreTimestampType>;

export type User = UserInterface<Date>;

type UserDates<DATE extends Date | FirestoreTimestampType> = Pick<Dates<DATE>, 'updatedAt'>;

interface UserInterface<DATE extends Date | FirestoreTimestampType> extends UserDates<DATE> {
  // permission for app and backend
  role: UserRole;

  // user details
  title: string;
  firstname: string;
  lastname: string;
  address: UserAddress;
  company: string;
  job: string;
  contactInformation: UserContactInformation;
  profileImage: UserProfileImage;
  favorites: UserFavorites;
  classifieds: UserClassifieds;
  interests: UserInterests;
  about: string;

  // generic
  sortIndex: string;

  // external user management
  externalIds: UserExternalIds;

  // event data
  eventParticipation: UserEventParticipation;
  eventInteraction: UserEventInteraction;

  // app
  app: UserAppInterface<DATE>;

  // state
  disabledAt: DATE;
  eventParticipationManuallyManaged: boolean;
  hideOnEventParticipantsList: boolean;
}

export interface UserCreate
  extends Pick<
      UserDocument,
      | 'firstname'
      | 'lastname'
      | 'address'
      | 'company'
      | 'job'
      | 'role'
      | 'contactInformation'
      | 'eventParticipationManuallyManaged'
      | 'hideOnEventParticipantsList'
    >,
    Partial<Pick<UserDocument, 'eventParticipation'>>,
    O.Partial<Pick<UserDocument, 'externalIds'>, 'deep'> {}

export interface UserProfileUpdate
  extends Pick<UserDocument, 'firstname' | 'lastname' | 'address' | 'classifieds' | 'interests' | 'about'>,
    O.P.Pick<UserDocument, ['contactInformation', 'phone' | 'mobile' | 'emailPublic' | 'socialMedia']>,
    Partial<Pick<UserDocument, 'sortIndex'>> {}

export enum UserRole {
  ADMINISTRATOR = 'administrator',
  TESTER = 'tester',
  PARTICIPANT = 'participant',
  SUPER_ADMIN = 'superadmin',
}

export type UserRoles = UserRole[];

export interface UserAddress {
  street: string;
  zip: string;
  city: string;
}

export interface UserContactInformation {
  email: string;
  emailPublic: string;
  phone: string;
  mobile: string;
  socialMedia: SocialMedia;
}

export interface UserProfileImage {
  original: Image;
  thumbnail: Image;
}

export interface UserExternalIds {
  amadeusId: string;
  coladaId: string;
  armidaId: string;
}

export type UserApp = UserAppInterface<FirestoreTimestampType>;

interface UserAppInterface<DATE extends Date | FirestoreTimestampType> {
  installedVersion: string;
  lastLoginAt: DATE;
  fcmTokens: UserFCMTokens;
  account: UserAppAccount;
  settings: UserAppSettings;
}

export type UserAppEntityInterface = UserAppInterface<Date>;

export interface UserFCMTokens {
  [token: string]: true;
}

export interface UserAppAccount {
  activated: boolean;
  activationToken: string;
}

export interface UserAppSettings {
  locale: Locale;
  privacy: UserAppPrivacySettings;
  chat: UserAppChatSettings;
}

export interface UserAppPrivacySettings {
  showAddress: boolean;
  showMobile: boolean;
  showPhone: boolean;
  showEmail: boolean;
  showSocialMedia: boolean;
}

// INFO: chat is per default undefined (null), until the user has logged-in for the first time
//       then the chat will be enabled through the cloud functions
export interface UserAppChatSettings {
  enabled: null | boolean;
  notificationsEnabled: null | boolean;
}

export interface UserEventParticipation {
  [eventId: string]: UserEventParticipationInfo;
}

export type UserEventParticipationInfo = {[K in UserEventParticipationType]?: UserEventParticipationTypings[K]};

export enum UserEventParticipationType {
  SECTOR_ID = 'sectorId',
  SESSIONS = 'sessions',
  COMPANY = 'company',
  JOB = 'job',
}

export interface EventParticipationSession {
  [sessionId: string]: {};
}

export interface UserEventParticipationTypings {
  [UserEventParticipationType.SECTOR_ID]: string;
  [UserEventParticipationType.SESSIONS]: EventParticipationSession;
  [UserEventParticipationType.COMPANY]: string;
  [UserEventParticipationType.JOB]: string;
}

export type UserClassifieds = {[classifiedsId: string]: UserClassifiedsByType};

export type UserClassifiedsType = 'search' | 'offer';

export type UserClassifiedsByType = Partial<Record<UserClassifiedsType, true>>;
export type UserClassifiedsIdsGroupedByType = Record<UserClassifiedsType, string[]>;

export type UserInterests = {[interestsId: string]: true};

export type UserInterestsArray = string[];

export interface UserEventInteraction {
  ratings: UserRatingDocumentMeta;
  votings: UserVotings;
}

export type UserContactInformationFormControlModel = FormControlType<UserContactInformation>;
export type UserAddressFormControlModel = FormControlType<UserAddress>;
