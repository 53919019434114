import {InjectionToken} from '@angular/core';

import {UserDocument} from '../entities/user/user.interface';

export enum CloudFunctions {
  CORS_PROXY = 'corsProxy',
  HAS_USER_ACTIVATED = 'hasUserActivated',
  IMPORT_NEWS = 'importNews',
  IMPORT_USER_FROM_API = 'importUserFromApi',
  IMPORT_USERS = 'importUsers',
  SEND_ACTIVATION_TOKEN_MAIL = 'sendActivationTokenMail',
  UPDATE_CURRENT_USER_EMAIL = 'updateCurrentUserEmail',
  UPDATE_SPEAKER_SORT_INDEX = 'updateSpeakerSortIndex',
  UPDATE_USER_AUTH_EMAIL = 'updateUserAuthEmail',
  UPDATE_USER_SORT_INDEX = 'updateUserSortIndex',
  USER_DATA_CHANGED = 'userDataChanged',
  VERSION_INFO = 'versionInfo',
}

export const CLOUD_FUNCTIONS_AUTHENTICATION_BEARER_PREFIX_KEY = 'Bearer ';

export const CLOUD_FUNCTIONS_AUTHENTICATION_BEARER_TOKEN = new InjectionToken<string>('Cloud Functions Bearer Authentication Token');

export const CLOUD_FUNCTIONS_URL = new InjectionToken<string>('Cloud Functions URL');

// TODO: add correct interface from cloud-functions to module, to access it from the app and backend
export interface CloudFunctionsResponseBody<DATA = any> {
  status: number;
  data: DATA;
  errors: any[];
}

// TODO: add correct interface from cloud-functions to module, to access it from the app and backend
export type CloudFunctionsImportUsersResponseBody = Record<'code' | 'message', string> | {result: CloudFunctionsImportUserResult};

export interface ImportUserResultDetail {
  uid: string;
  code: string;
  message: string;
  email: string;
  status?: 'created' | 'updated';
  amadeusId: string;
  coladaId: string;
  armidaId: string;
  userDocument: UserDocument;
}

// TODO: add correct interface from cloud-functions to module, to access it from the app and backend
export interface CloudFunctionsImportUserResult {
  errorArray: ImportUserResultDetail[];
  successArray: ImportUserResultDetail[];
  creationCount: number;
  updatedCount: number;
  errorCount: number;
}

// TODO: add correct interface from cloud-functions to module, to access it from the app and backend
export type CloudFunctionsImportUserFromApiResponseBody = Record<'code' | 'message', string> | {result: CloudFunctionsImportUserResult};
