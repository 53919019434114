import {PartnerCategoryDocumentI18n} from '../entities/partner-category/partner-category-document.interface';
import {PartnerCategoryExternalIds} from '../entities/partner-category-legacy/partner-category.interface';

export class PartnerCategoryHelper {
  public static initExternalIds(amadeusId = '', nzzConnectCmsId = ''): PartnerCategoryExternalIds {
    return {amadeusId, nzzConnectCmsId};
  }

  public static initI18n({name = ''}: Partial<PartnerCategoryDocumentI18n> = {}): PartnerCategoryDocumentI18n {
    return {name};
  }
}
