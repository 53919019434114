import {UserHelper} from '../../helpers/user.helper';
import {EntityHelper} from '../entity.helper';
import {Entity} from '../entity.interface';
import {FirebaseDocumentObject} from '../firebase-document-snapshot.interface';
import {BaseUser} from './base-user.abstract';
import {
  EventParticipationSession,
  User,
  UserAddress,
  UserAppEntityInterface,
  UserClassifieds,
  UserContactInformation,
  UserDocument,
  UserEventInteraction,
  UserEventParticipation,
  UserEventParticipationType,
  UserEventParticipationTypings,
  UserExternalIds,
  UserInterests,
  UserProfileImage,
  UserRole,
} from './user.interface';
import {UserFavorites} from './user-favorites.interface';

export class UserEntity extends BaseUser<UserContactInformation> implements Entity<UserEntity, UserDocument>, User {
  public constructor(
    public id: string = null,
    public role: UserRole = UserRole.PARTICIPANT,
    public title: string = null,
    public firstname: string = null,
    public lastname: string = null,
    public address: UserAddress = UserHelper.initAddress(),
    public company: string = null,
    public job: string = null,
    public contactInformation: UserContactInformation = UserHelper.initContactInformation(),
    public profileImage: UserProfileImage = UserHelper.initImage(),
    public favorites: UserFavorites = {},
    public sortIndex: string = null,
    public updatedAt: Date = new Date(),
    public externalIds: UserExternalIds = UserHelper.initExternalIds(),
    public eventParticipation: UserEventParticipation = {},
    public app: UserAppEntityInterface = UserHelper.initApp(),
    public classifieds: UserClassifieds = UserHelper.initUserClassifieds(),
    public interests: UserInterests = {},
    public disabledAt: Date | null = null,
    public about: string = '',
    public eventInteraction: UserEventInteraction = UserHelper.initUserEventInteraction(),
    public eventParticipationManuallyManaged: boolean = false,
    public hideOnEventParticipantsList: boolean = false,
  ) {
    super();
  }

  public get isAdministrator(): boolean {
    return [UserRole.ADMINISTRATOR, UserRole.SUPER_ADMIN].includes(this.role);
  }

  public get isSuperAdmin(): boolean {
    return this.role === UserRole.SUPER_ADMIN;
  }

  public get isTester(): boolean {
    return this.role === UserRole.TESTER;
  }

  public get isParticipant(): boolean {
    return this.role === UserRole.PARTICIPANT;
  }

  public get hasAnyExternalIds(): boolean {
    return Object.values(this.externalIds).some((v) => v);
  }

  public getDocumentFields(): UserDocument {
    return {
      role: this.role,
      title: this.title,
      firstname: this.firstname,
      lastname: this.lastname,
      address: this.address,
      company: this.company,
      job: this.job,
      contactInformation: this.contactInformation,
      profileImage: this.profileImage,
      favorites: this.favorites,
      sortIndex: this.sortIndex,
      updatedAt: EntityHelper.fromDateToTimestamp(this.updatedAt),
      externalIds: this.externalIds,
      eventParticipation: this.eventParticipation,
      app: {...this.app, lastLoginAt: EntityHelper.fromDateToTimestamp(this.app.lastLoginAt)},
      classifieds: this.classifieds,
      interests: this.interests,
      disabledAt: EntityHelper.fromDateToTimestamp(this.disabledAt),
      about: this.about,
      eventInteraction: this.eventInteraction,
      eventParticipationManuallyManaged: this.eventParticipationManuallyManaged,
      hideOnEventParticipantsList: this.hideOnEventParticipantsList,
    };
  }

  public fromDocument(document: FirebaseDocumentObject<UserDocument>): UserEntity {
    return new UserEntity(
      document.id,
      document.role,
      document.title,
      document.firstname,
      document.lastname,
      document.address,
      document.company,
      document.job,
      document.contactInformation,
      document.profileImage,
      document.favorites,
      document.sortIndex,
      EntityHelper.fromTimestampToDate(document.updatedAt),
      document.externalIds,
      document.eventParticipation,
      {...document.app, lastLoginAt: EntityHelper.fromTimestampToDate(document.app.lastLoginAt)},
      document.classifieds,
      document.interests,
      EntityHelper.fromTimestampToDate(document.disabledAt),
      document.about || '',
      document.eventInteraction,
      document.eventParticipationManuallyManaged,
      document.hideOnEventParticipantsList,
    );
  }

  public fcmTokensToArray(): string[] {
    return Object.keys(this.app.fcmTokens);
  }

  public setSectorIdForEvent(eventId: string, sectorId: string) {
    this.setEventParticipationInfo(eventId, UserEventParticipationType.SECTOR_ID, sectorId);
  }

  public removeSectorForEvent(eventId: string) {
    this.removeEventParticipationInfo(eventId, UserEventParticipationType.SECTOR_ID);
  }

  public addEventId(eventId: string) {
    this.setEventParticipationInfo(eventId);
  }

  public removeEventId(eventId: string) {
    this.removeEventParticipationInfo(eventId);
  }

  public getSessionIdsForEvent(eventId: string): EventParticipationSession {
    return this.getEventParticipationInfo(eventId, UserEventParticipationType.SESSIONS);
  }

  public setSessions(eventId: string, eventParticipationSession: EventParticipationSession) {
    this.setEventParticipationInfo(eventId, UserEventParticipationType.SESSIONS, eventParticipationSession);
  }

  public addSession(eventId: string, sessionId: string) {
    this.setEventParticipationInfo(eventId, UserEventParticipationType.SESSIONS, {
      ...this.getSessionIdsForEvent(eventId),
      [sessionId]: {},
    });
  }

  public setEventCompany(eventId: string, company: string) {
    this.setEventParticipationInfo(eventId, UserEventParticipationType.COMPANY, company);
  }

  public setEventJob(eventId: string, job: string) {
    this.setEventParticipationInfo(eventId, UserEventParticipationType.JOB, job);
  }

  public removeSession(eventId: string) {
    this.removeEventParticipationInfo(eventId, UserEventParticipationType.SESSIONS);
  }

  public hasVoted(votingId: string): boolean {
    return this.eventInteraction.votings?.[votingId] !== undefined;
  }

  public hasFavorite(userId: string): boolean {
    return this.id !== userId && Object.prototype.hasOwnProperty.call(this.favorites, userId);
  }

  public isParticipatingAtEvent(eventId: string): boolean {
    return this.hasEventWithId(eventId) || this.isAdministrator;
  }

  public hasSessionRated(sessionId: string): boolean {
    return this.eventInteraction.ratings.sessions?.[sessionId] !== undefined;
  }

  private setEventParticipationInfo(eventId: string);
  private setEventParticipationInfo<KEY extends UserEventParticipationType>(
    eventId: string,
    key: KEY,
    value: UserEventParticipationTypings[KEY],
  );
  private setEventParticipationInfo<KEY extends UserEventParticipationType>(
    eventId: string,
    key?: KEY,
    value?: UserEventParticipationTypings[KEY],
  ) {
    if (!this.eventParticipation[eventId]) {
      this.eventParticipation[eventId] = {};
    }

    if (key) {
      this.eventParticipation[eventId][key] = value;
    }
  }

  private removeEventParticipationInfo(eventId: string, key?: UserEventParticipationType) {
    if (!!key && this.eventParticipation[eventId][key]) {
      delete this.eventParticipation[eventId][key];
    }

    if (!key && this.eventParticipation[eventId]) {
      delete this.eventParticipation[eventId];
    }
  }
}
