import {Locale} from '../../interfaces/environment.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {SessionModel} from '../session/session.model';
import {UserEntity} from '../user/user.entity';
import {VotingType} from '../voting/voting-document.interface';
import {LocaleVotingEntity} from './locale-voting.entity';
import {MetadataVotingEntity} from './metadata-voting.entity';

export class VotingLegacyEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataVotingEntity,
    private localizedData: LocaleEntityType<LocaleVotingEntity>,
  ) {}

  public get isDraft(): boolean {
    return this.metadata.isDraft;
  }

  public get isTesting(): boolean {
    return this.metadata.isTesting;
  }

  public get isOpen(): boolean {
    return this.metadata.isOpen;
  }

  public get isClosed(): boolean {
    return this.metadata.isClosed;
  }

  public get isGlobalScope(): boolean {
    return this.metadata.isGlobalScope;
  }

  public get isSessionScope(): boolean {
    return this.metadata.isSessionScope;
  }

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocaleVotingEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataVotingEntity {
    return this.metadata;
  }

  public getId(): string {
    return this.id;
  }

  public getEventReference(): string {
    return this.metadata.eventId;
  }

  public getSession(): string | VotingType {
    return this.metadata.session;
  }

  public getStatus(): string {
    return this.metadata.status;
  }

  public getChartType(): string {
    return this.metadata.chartType;
  }

  public getResult(): Array<number> {
    return this.metadata.result;
  }

  public getQuestion(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].question;
  }

  public getChoices(locale: Locale = Locale.DE): Array<string> {
    return this.localizedData[locale].choices;
  }

  /**
   * Only used in mobile.
   */
  public isVisibleForUser(user: UserEntity, session?: SessionModel): boolean {
    return this.metadata.isVisibleForUser(user, session);
  }

  public isOpenForUser(user: UserEntity): boolean {
    return this.metadata.isOpenForUser(user);
  }
}
