import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {SubscriptionManager} from '@semabit/nzz-connect-common-ng/components/context/subscription-manager';
import {AwardFinalistModels} from '@semabit/nzz-connect-common-ng/entities/award-finalist/award-finalist.model';
import {LocalizedBannerEntity} from '@semabit/nzz-connect-common-ng/entities/banner-legacy/localized-banner.entity';
import {ParticipantEntities} from '@semabit/nzz-connect-common-ng/entities/participant/participant.entity';
import {LocalizedPartnerCategoryEntity} from '@semabit/nzz-connect-common-ng/entities/partner-category-legacy/localized-partner-category.entity';
import {PlanModels} from '@semabit/nzz-connect-common-ng/entities/plan/plan.model';
import {RoomModels} from '@semabit/nzz-connect-common-ng/entities/room/room.model';
import {SectorModels} from '@semabit/nzz-connect-common-ng/entities/sector/sector.model';
import {LocalizedSessionEntity} from '@semabit/nzz-connect-common-ng/entities/session-legacy/localized-session.entity';
import {LocalizedSpeakerEntity} from '@semabit/nzz-connect-common-ng/entities/speaker-legacy/localized-speaker.entity';
import {LocalizedVotingEntity} from '@semabit/nzz-connect-common-ng/entities/voting-legacy/localized-voting.entity';
import {Locale} from '@semabit/nzz-connect-common-ng/interfaces/environment.interface';
import {SubscriptionData, SubscriptionSubject} from '@semabit/nzz-connect-common-ng/interfaces/subscription-data.interface';
import {AwardFinalistRepository} from '@semabit/nzz-connect-common-ng/repositories/award-finalist.repository';
import {BannerLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/banner.legacy.repository';
import {ParticipantRepository} from '@semabit/nzz-connect-common-ng/repositories/participant.repository';
import {PartnerCategoryLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/partner-category.legacy.repository';
import {PlanRepository} from '@semabit/nzz-connect-common-ng/repositories/plan.repository';
import {RoomRepository} from '@semabit/nzz-connect-common-ng/repositories/room.repository';
import {SectorRepository} from '@semabit/nzz-connect-common-ng/repositories/sector.repository';
import {SessionLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/session.legacy.repository';
import {SpeakerLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/speaker.legacy.repository';
import {VotingLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/voting.legacy.repository';
import {LoggerService} from '@semabit/nzz-connect-common-ng/services/logging/logger.service';

const VOTINGS_SUBSCRIPTION_KEY = 'event-context.votings';
const SESSIONS_SUBSCRIPTION_KEY = 'event-context.sessions';
const SPEAKERS_SUBSCRIPTION_KEY = 'event-context.speakers';
const PARTNER_CATEGORIES_SUBSCRIPTION_KEY = 'event-context.partner-categories';
const BANNERS_SUBSCRIPTION_KEY = 'event-context.banners';
const ROOMS_SUBSCRIPTION_KEY = 'event-context.rooms';
const SECTORS_SUBSCRIPTION_KEY = 'event-context.sectors';
const AWARD_FINALISTS_SUBSCRIPTION_KEY = 'event-context.award-finalists';
const PLANS_SUBSCRIPTION_KEY = 'event-context.plans';
const PARTICIPANTS_SUBSCRIPTION_KEY = 'event-context.participants';

@Injectable({providedIn: 'root'})
export class EventContextService extends SubscriptionManager {
  public votings: SubscriptionSubject<LocalizedVotingEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedVotingEntity[]>>({
    loading: false,
    data: [],
  });
  public sessions: SubscriptionSubject<LocalizedSessionEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedSessionEntity[]>>({
    loading: false,
    data: [],
  });
  public speakers: SubscriptionSubject<LocalizedSpeakerEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedSpeakerEntity[]>>({
    loading: false,
    data: [],
  });
  public partnerCategories: SubscriptionSubject<LocalizedPartnerCategoryEntity[]> = new BehaviorSubject<
    SubscriptionData<LocalizedPartnerCategoryEntity[]>
  >({loading: false, data: []});
  public banners: SubscriptionSubject<LocalizedBannerEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedBannerEntity[]>>({
    loading: false,
    data: [],
  });
  public rooms: SubscriptionSubject<RoomModels> = new BehaviorSubject<SubscriptionData<RoomModels>>({loading: false, data: []});
  public sectors: SubscriptionSubject<SectorModels> = new BehaviorSubject<SubscriptionData<SectorModels>>({loading: false, data: []});
  public awardFinalists: SubscriptionSubject<AwardFinalistModels> = new BehaviorSubject<SubscriptionData<AwardFinalistModels>>({
    loading: false,
    data: [],
  });
  public plans = new BehaviorSubject<SubscriptionData<PlanModels>>({loading: false, data: []});
  public participants: SubscriptionSubject<ParticipantEntities> = new BehaviorSubject<SubscriptionData<ParticipantEntities>>({
    loading: false,
    data: [],
  });
  public questions: SubscriptionSubject<LocalizedSessionEntity[]> = new BehaviorSubject<SubscriptionData<LocalizedSessionEntity[]>>({
    loading: false,
    data: [],
  });

  public constructor(
    private votingRepository: VotingLegacyRepository,
    private sessionsRepository: SessionLegacyRepository,
    private speakersRepository: SpeakerLegacyRepository,
    private bannerRepository: BannerLegacyRepository,
    private partnerCategoryRepository: PartnerCategoryLegacyRepository,
    private roomRepository: RoomRepository,
    private sectorRepository: SectorRepository,
    private awardFinalistRepository: AwardFinalistRepository,
    private planRepository: PlanRepository,
    private participantRepository: ParticipantRepository,
    logger: LoggerService,
  ) {
    super(logger);
  }

  public clearSubscriptions(): void {
    super.clearSubscriptions();
    this.votings.next({loading: false, data: []});
    this.sessions.next({loading: false, data: []});
    this.speakers.next({loading: false, data: []});
    this.partnerCategories.next({loading: false, data: []});
    this.banners.next({loading: false, data: []});
    this.rooms.next({loading: false, data: []});
    this.sectors.next({loading: false, data: []});
    this.awardFinalists.next({loading: false, data: []});
    this.plans.next({loading: false, data: []});
    this.participants.next({loading: false, data: []});
  }

  public startSubscriptions(eventId: string, localeId: Locale): void {
    this.subscribeBanners(eventId, localeId);
    this.subscribeVoting(eventId, localeId);
    this.subscribeSessions(eventId, localeId);
    this.subscribeSpeakers(eventId, localeId);
    this.subscribePartners(eventId, localeId);
    this.subscribeRooms(eventId, localeId);
    this.subscribeSectors(eventId, localeId);
    this.subscribeAwardFinalists(eventId, localeId);
    this.subscribePlans(eventId, localeId);
    this.subscribeParticipants(eventId);
  }

  public refreshSubscriptions(eventId: string, localeId: Locale): void {
    this.clearSubscriptions();
    this.startSubscriptions(eventId, localeId);
  }

  private subscribeVoting(eventId: string, localeId: Locale): void {
    this.votings.next({...this.votings.value, loading: true});

    this.addSubscription(
      VOTINGS_SUBSCRIPTION_KEY,
      this.votingRepository
        .getActiveVotingsByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.votings.next({...this.votings.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(VOTINGS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeSessions(eventId: string, localeId: Locale): void {
    this.sessions.next({...this.sessions.value, loading: true});

    this.addSubscription(
      SESSIONS_SUBSCRIPTION_KEY,
      this.sessionsRepository
        .getSessionsByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.sessions.next({...this.sessions.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(SESSIONS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeSpeakers(eventId: string, localeId: Locale): void {
    this.speakers.next({...this.speakers.value, loading: true});

    this.addSubscription(
      SPEAKERS_SUBSCRIPTION_KEY,
      this.speakersRepository
        .getSpeakersByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.speakers.next({...this.speakers.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(SPEAKERS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribePartners(eventId: string, localeId: Locale): void {
    this.partnerCategories.next({...this.partnerCategories.value, loading: true});

    this.addSubscription(
      PARTNER_CATEGORIES_SUBSCRIPTION_KEY,
      this.partnerCategoryRepository
        .getActivePartnerCategoriesWithPartnersByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.partnerCategories.next({...this.partnerCategories.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(PARTNER_CATEGORIES_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeBanners(eventId: string, localeId: Locale): void {
    this.banners.next({...this.banners.value, loading: true});

    this.addSubscription(
      BANNERS_SUBSCRIPTION_KEY,
      this.bannerRepository
        .getBannersByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.banners.next({...this.banners.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(BANNERS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeRooms(eventId: string, localeId: Locale) {
    this.rooms.next({...this.rooms.value, loading: true});

    this.addSubscription(
      ROOMS_SUBSCRIPTION_KEY,
      this.roomRepository
        .getAllByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.rooms.next({...this.rooms.value, loading: true, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(ROOMS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeSectors(eventId: string, localeId: Locale) {
    this.sectors.next({...this.sectors.value, loading: true});

    this.addSubscription(
      SECTORS_SUBSCRIPTION_KEY,
      this.sectorRepository
        .getAllByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.sectors.next({...this.sectors.value, loading: true, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(SECTORS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeAwardFinalists(eventId: string, localeId: Locale) {
    this.awardFinalists.next({...this.awardFinalists.value, loading: true});

    this.addSubscription(
      AWARD_FINALISTS_SUBSCRIPTION_KEY,
      this.awardFinalistRepository
        .getAllByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.awardFinalists.next({...this.awardFinalists.value, loading: true, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(AWARD_FINALISTS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribePlans(eventId: string, localeId: Locale) {
    this.plans.next({...this.plans.value, loading: true});

    this.addSubscription(
      PLANS_SUBSCRIPTION_KEY,
      this.planRepository
        .getAllByEventAndLocale(eventId, localeId)
        .pipe(tap((data) => this.plans.next({...this.plans.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(PLANS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeParticipants(eventId: string) {
    this.participants.next({...this.participants.value, loading: true});

    this.addSubscription(
      PARTICIPANTS_SUBSCRIPTION_KEY,
      this.participantRepository
        .getParticipantsByEventId(eventId)
        .pipe(
          catchError((error) => {
            this.subscriptionErrorHandler(PARTICIPANTS_SUBSCRIPTION_KEY, error);

            return of([]);
          }),
          tap((data) => this.participants.next({...this.participants.value, loading: false, data})),
        )
        .subscribe(),
    );
  }

  private subscriptionErrorHandler(source: string, error: any): void {
    this.logger.error(source, error);
  }
}
