import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {UserEntity} from '../user/user.entity';
import {LocaleEventEntity} from './locale-event.entity';
import {MetadataEventEntity} from './metadata-event.entity';

export class EventLegacyEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataEventEntity,
    private localizedData: LocaleEntityType<LocaleEventEntity>,
  ) {}

  public get isVotingEnabled(): boolean {
    return this.metadata.isVotingEnabled;
  }

  public get isQuestionEnabled(): boolean {
    return this.metadata.isQuestionEnabled;
  }

  public get isDraft(): boolean {
    return this.metadata.isDraft;
  }

  public get isPreannouncement(): boolean {
    return this.metadata.isPreannouncement;
  }

  public get isActive(): boolean {
    return this.metadata.isActive;
  }

  public get isArchived(): boolean {
    return this.metadata.isArchived;
  }

  public get isLivestreamEnabled() {
    return this.metadata.isLivestreamEnabled;
  }

  public get isLivestreamPublic() {
    return this.metadata.isLivestreamPublic;
  }

  public get isManualParticipantImport(): boolean {
    return this.metadata.isManualParticipantImport;
  }

  public get isAutomaticParticipantImport(): boolean {
    return this.metadata.isAutomaticParticipantImport;
  }

  public get isAutomaticAmadeusParticipantImport(): boolean {
    return this.metadata.isAutomaticAmadeusParticipantImport;
  }

  public get isAutomaticColadaParticipantImport(): boolean {
    return this.metadata.isAutomaticColadaParticipantImport;
  }

  public get isParticipantImportAllowed(): MetadataEventEntity['isParticipantImportAllowed'] {
    return this.metadata.isParticipantImportAllowed;
  }

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocaleEventEntity {
    return this.localizedData[locale];
  }

  public getMetadataEntity(): MetadataEventEntity {
    return this.metadata;
  }

  public getName(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].name;
  }

  public getLocation(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].location;
  }

  public getSpeakers(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].speakers;
  }

  public getRegistrationLink(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].registrationLink;
  }

  public getRegistrationLabel(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].registrationLabel;
  }

  public getPrice(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].price;
  }

  public getParticipants(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].participants;
  }

  public getLanguages(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].languages;
  }

  public getDescription(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].description;
  }

  public getDescriptionMarkdown(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].descriptionMarkdown;
  }

  public getTimes(): any[] {
    return this.metadata.times;
  }

  public getWifiSsid(): string {
    return this.metadata.wifiSsid;
  }

  public getWifiPw(): string {
    return this.metadata.wifiPw;
  }

  public getHashtag(): string {
    return this.metadata.hashtag;
  }

  public getStatus(): string {
    return this.metadata.status;
  }

  public getEventType(): string {
    return this.metadata.eventType;
  }

  public getId(): string {
    return this.id;
  }

  public getAmadeusId(): string {
    return this.metadata.amadeusId;
  }

  public getBackground(): Image {
    return this.metadata.background;
  }

  public getBackgroundOverlay(): Image {
    return this.metadata.backgroundOverlay;
  }

  public getLogo(): Image {
    return this.metadata.logo;
  }

  public getBackgroundColor(): string {
    return this.metadata.backgroundColor;
  }

  public getBackgroundOppositeColor(): string {
    return this.metadata.backgroundOppositeColor;
  }

  public getAccentColor(): string {
    return this.metadata.accentColor;
  }

  public getAccentOppositeColor(): string {
    return this.metadata.accentOppositeColor;
  }

  public getNewsUrl(): string {
    return this.metadata.newsUrl;
  }

  public getAdditionalLink(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].additionalLink;
  }

  public getAdditionalLabel(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].additionalLabel;
  }

  public getLocationLink(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].locationLink;
  }

  public getBottomNavigationLabel(): string {
    return this.metadata.bottomNavigationLabel;
  }

  public getSupportedLanguages(): Locale[] {
    return this.metadata.supportedLanguages;
  }

  public getFallbackLanguage(): Locale {
    return this.metadata.fallbackLanguage;
  }

  public getMetadataAward() {
    return this.metadata.award;
  }

  public getLocaleAward(locale: Locale = Locale.DE) {
    return this.localizedData[locale].award;
  }

  public hasAdditionalLink(locale: Locale = Locale.DE): boolean {
    return this.localizedData[locale].hasAdditionalLink;
  }

  public isLanguageSupported(locale: Locale): boolean {
    return this.metadata.isLanguageSupported(locale);
  }

  public getLivestreamUrl() {
    return this.metadata.livestreamUrl;
  }

  public isLivestreamAvailable(user: UserEntity): boolean {
    return this.isLivestreamEnabled && !!this.getLivestreamUrl() && (this.isLivestreamPublic || user.hasEventWithId(this.id));
  }

  public getTeaser(): MetadataEventEntity['teaser'] {
    return this.metadata.teaser;
  }

  public getParticipantImportMode(): MetadataEventEntity['participantImportMode'] {
    return this.metadata.participantImportMode;
  }

  public getSocialMedia(): MetadataEventEntity['socialMedia'] {
    return this.metadata.socialMedia;
  }

  public get hasSocialMedia(): MetadataEventEntity['hasSocialMedia'] {
    return this.metadata.hasSocialMedia;
  }

  public getColadaId(): string {
    return this.metadata.coladaId;
  }

  public getParticipantImportSystem(): MetadataEventEntity['participantImportSystem'] {
    return this.metadata.participantImportSystem;
  }

  public getNzzConnectCmsId(): string {
    return this.metadata.nzzConnectCmsId;
  }
}
