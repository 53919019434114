import {ImageHelper} from '../../helpers/image.helper';
import {NewsroomHelper} from '../../helpers/newsroom.helper';
import {Image} from '../../interfaces/image.interface';
import {EventTypeNewsroom} from '../event-type/event-type-document.interface';

export class MetadataEventTypeEntity {
  public constructor(
    private _logo: Image = ImageHelper.init(),
    private _newsroom: EventTypeNewsroom = NewsroomHelper.init(),
    public icon: Image = ImageHelper.init(),
  ) {}

  public get logo(): Image {
    return this._logo;
  }

  public set logo(value: Image) {
    this._logo = value;
  }

  public get newsroom(): EventTypeNewsroom {
    return this._newsroom;
  }

  public set newsroom(value: EventTypeNewsroom) {
    this._newsroom = value;
  }
}
