import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import firebase from 'firebase/compat/app';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {catchError, filter, map, pluck, tap} from 'rxjs/operators';

import {SubscriptionManager} from '@semabit/nzz-connect-common-ng/components/context/subscription-manager';
import {CurrentLocale} from '@semabit/nzz-connect-common-ng/current-locale';
import {AwardFinalistModels} from '@semabit/nzz-connect-common-ng/entities/award-finalist/award-finalist.model';
import {LocalizedBannerEntity} from '@semabit/nzz-connect-common-ng/entities/banner-legacy/localized-banner.entity';
import {ClassifiedsModels} from '@semabit/nzz-connect-common-ng/entities/classifieds/classifieds.model';
import {LocalizedEventEntity} from '@semabit/nzz-connect-common-ng/entities/event-legacy/localized-event.entity';
import {LocalizedEventTypeEntity} from '@semabit/nzz-connect-common-ng/entities/event-type-legacy/localized-event-type.entity';
import {InterestsModels} from '@semabit/nzz-connect-common-ng/entities/interests/interests.model';
import {NewsEntity} from '@semabit/nzz-connect-common-ng/entities/news/news.entity';
import {ParticipantEntities, ParticipantEntity} from '@semabit/nzz-connect-common-ng/entities/participant/participant.entity';
import {LocalizedPartnerCategoryEntity} from '@semabit/nzz-connect-common-ng/entities/partner-category-legacy/localized-partner-category.entity';
import {PlanModels} from '@semabit/nzz-connect-common-ng/entities/plan/plan.model';
import {RoomModels} from '@semabit/nzz-connect-common-ng/entities/room/room.model';
import {SectorModels} from '@semabit/nzz-connect-common-ng/entities/sector/sector.model';
import {LocalizedSessionEntity} from '@semabit/nzz-connect-common-ng/entities/session-legacy/localized-session.entity';
import {LocalizedSpeakerEntity} from '@semabit/nzz-connect-common-ng/entities/speaker-legacy/localized-speaker.entity';
import {UserEntity} from '@semabit/nzz-connect-common-ng/entities/user/user.entity';
import {LocalizedVotingEntity} from '@semabit/nzz-connect-common-ng/entities/voting-legacy/localized-voting.entity';
import {SubscriptionData, SubscriptionSubject} from '@semabit/nzz-connect-common-ng/interfaces/subscription-data.interface';
import {ClassifiedsRepository} from '@semabit/nzz-connect-common-ng/repositories/classifieds.repository';
import {EventLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/event.legacy.repository';
import {EventTypeLegacyRepository} from '@semabit/nzz-connect-common-ng/repositories/event-type.legacy.repository';
import {InterestsRepository} from '@semabit/nzz-connect-common-ng/repositories/interests.repository';
import {NewsRepository} from '@semabit/nzz-connect-common-ng/repositories/news.repository';
import {ParticipantRepository} from '@semabit/nzz-connect-common-ng/repositories/participant.repository';
import {UserRepository} from '@semabit/nzz-connect-common-ng/repositories/user.repository';
import {FirebaseAuthService} from '@semabit/nzz-connect-common-ng/services/auth/firebase-auth.service';
import {LoggerService} from '@semabit/nzz-connect-common-ng/services/logging/logger.service';

import {EventContextService} from '@app/modules/shared-components/services/event.context.service';
import User = firebase.User;
import {QuestionEntity} from '@semabit/nzz-connect-common-ng/entities/question/question.entity';

const FIREBASE_AUTH_SUBSCRIPTION_KEY = 'context.firebase.auth-state';
const USER_SUBSCRIPTION_KEY = 'context.user';
const PARTICIPANT_SUBSCRIPTION_KEY = 'context.user-as-participant';
const EVENT_SUBSCRIPTION_KEY = 'context.event';
const EVENTS_SUBSCRIPTION_KEY = 'context.events';
const EVENT_TYPES_SUBSCRIPTION_KEY = 'context.event-types';
const APP_NEWS_SUBSCRIPTION_KEY = 'context.app-news';
const INTERNAL_EVENT_NEWS_SUBSCRIPTION_KEY = 'internal.context.event-news';
const EVENT_NEWS_SUBSCRIPTION_KEY = 'context.event-news';
const CLASSIFIEDS_SUBSCRIPTION_KEY = 'context.classifieds';
const INTERESTS_SUBSCRIPTION_KEY = 'context.interests';

@Injectable({providedIn: 'root'})
export class ContextService extends SubscriptionManager {
  public static readonly eventIdentifierName = 'eventId';

  public isEventRelatedPage: BehaviorSubject<boolean>;

  public speakers: SubscriptionSubject<LocalizedSpeakerEntity[]>;
  public sessions: SubscriptionSubject<LocalizedSessionEntity[]>;
  public eventParticipants: SubscriptionSubject<ParticipantEntities>;
  public partnerCategories: SubscriptionSubject<LocalizedPartnerCategoryEntity[]>;
  public votings: SubscriptionSubject<LocalizedVotingEntity[]>;
  public questions: SubscriptionSubject<QuestionEntity[]>;
  public awardFinalists: SubscriptionSubject<AwardFinalistModels>;
  public plans: SubscriptionSubject<PlanModels>;
  public eventNews: SubscriptionSubject<NewsEntity[]>;
  public banners: SubscriptionSubject<LocalizedBannerEntity[]>;
  public rooms: SubscriptionSubject<RoomModels>;
  public classifieds: SubscriptionSubject<ClassifiedsModels>;
  public interests: SubscriptionSubject<InterestsModels>;
  public sectors: SubscriptionSubject<SectorModels>;

  public user: SubscriptionSubject<UserEntity>;
  public participant: SubscriptionSubject<ParticipantEntity>;
  public events: SubscriptionSubject<LocalizedEventEntity[]>;
  public eventTypes: SubscriptionSubject<LocalizedEventTypeEntity[]>;
  public event: SubscriptionSubject<LocalizedEventEntity>;
  public appNews: SubscriptionSubject<NewsEntity[]>;

  private firebaseUser: User = null;

  private userFetch$: Subject<void>;

  private currentUserId = '';

  public constructor(
    protected logger: LoggerService,
    private currentLocale: CurrentLocale,
    private router: Router,
    private route: ActivatedRoute,
    private eventRepository: EventLegacyRepository,
    private eventTypeRepository: EventTypeLegacyRepository,
    private userRepository: UserRepository,
    private participantRepository: ParticipantRepository,
    private newsRepository: NewsRepository,
    private classifiedsRepository: ClassifiedsRepository,
    private interestsRepository: InterestsRepository,
    private firebaseAuthService: FirebaseAuthService,
    private eventContextService: EventContextService,
  ) {
    super(logger);
    this.eventParticipants = this.eventContextService.participants;
    this.votings = this.eventContextService.votings;
    this.sessions = this.eventContextService.sessions;
    this.speakers = this.eventContextService.speakers;
    this.partnerCategories = this.eventContextService.partnerCategories;
    this.banners = this.eventContextService.banners;
    this.rooms = this.eventContextService.rooms;
    this.sectors = this.eventContextService.sectors;
    this.awardFinalists = this.eventContextService.awardFinalists;
    this.plans = this.eventContextService.plans;

    this.isEventRelatedPage = new BehaviorSubject<boolean>(false);
    this.eventTypes = new BehaviorSubject<SubscriptionData<LocalizedEventTypeEntity[]>>({loading: false, data: []});
    this.event = new BehaviorSubject<SubscriptionData<LocalizedEventEntity>>({loading: false, data: null});
    this.user = new BehaviorSubject<SubscriptionData<UserEntity>>({loading: false, data: null});
    this.participant = new BehaviorSubject<SubscriptionData<ParticipantEntity>>({loading: false, data: null});
    this.appNews = new BehaviorSubject<SubscriptionData<NewsEntity[]>>({loading: false, data: null});
    this.eventNews = new BehaviorSubject<SubscriptionData<NewsEntity[]>>({loading: false, data: null});
    this.classifieds = new BehaviorSubject<SubscriptionData<ClassifiedsModels>>({loading: false, data: []});
    this.interests = new BehaviorSubject<SubscriptionData<InterestsModels>>({loading: false, data: []});
    this.events = new BehaviorSubject<SubscriptionData<LocalizedEventEntity[]>>({loading: false, data: []});

    this.subscribeUser();
    this.subscribeRouteParams();
    this.subscribeEvents();
    this.subscribeEventTypes();
    this.subscribeAppNews();
    this.subscribeEventNews();
    this.subscribeClassifieds();
    this.subscribeInterests();
  }

  public triggerUserFetch(): void {
    this.userFetch$.next();
  }

  private subscribeUser(): void {
    this.user.next({...this.user.value, loading: !this.user.value.data});
    this.participant.next({...this.participant.value, loading: !this.participant.value.data});
    this.userFetch$ = new Subject();

    this.userFetch$
      .pipe(
        tap(() => {
          this.addSubscription(
            FIREBASE_AUTH_SUBSCRIPTION_KEY,
            this.firebaseAuthService
              .getAuthState()
              .pipe(tap((response) => this.firebaseAuthServiceResponseHandler(response)))
              .subscribe({error: (error) => this.subscriptionErrorHandler(FIREBASE_AUTH_SUBSCRIPTION_KEY, error)}),
          );
        }),
      )
      .subscribe();

    this.triggerUserFetch();
  }

  private firebaseAuthServiceResponseHandler(firebaseUser: firebase.User): Promise<firebase.User> {
    this.firebaseUser = firebaseUser;
    if (this.firebaseUser) {
      this.subscribeUserByFirebaseUser();
      this.subscribeParticipantByFirebaseUser();
    } else {
      this.cancelSubscription(USER_SUBSCRIPTION_KEY);
      this.userResponseHandler(null);
    }

    return Promise.resolve(firebaseUser);
  }

  private subscribeUserByFirebaseUser(): void {
    this.addSubscription(
      USER_SUBSCRIPTION_KEY,
      this.userRepository
        .getUserByUid(this.firebaseUser.uid)
        .pipe(tap((response) => this.userResponseHandler(response)))
        .subscribe({error: (error) => this.subscriptionErrorHandler(USER_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeParticipantByFirebaseUser(): void {
    this.addSubscription(
      PARTICIPANT_SUBSCRIPTION_KEY,
      this.participantRepository
        .get(this.firebaseUser.uid)
        .pipe(
          catchError((error) => {
            this.subscriptionErrorHandler(PARTICIPANT_SUBSCRIPTION_KEY, error);
            return of(null);
          }),
          tap((data) => this.participant.next({...this.participant.value, loading: false, data})),
        )
        .subscribe({error: (error) => this.subscriptionErrorHandler(PARTICIPANT_SUBSCRIPTION_KEY, error)}),
    );
  }

  private userResponseHandler(response: UserEntity): void {
    this.user.next({...this.user.value, data: response, loading: false});

    if (response) {
      if (this.currentUserId !== response.id) {
        this.updateParticipantSubscription(response);
        this.currentUserId = response.id;
      }
    } else {
      this.currentUserId = '';
    }
  }

  private updateParticipantSubscription(user: UserEntity): void {
    if (!user) {
      this.cancelSubscription(PARTICIPANT_SUBSCRIPTION_KEY);
      this.participant.next({...this.participant.value, loading: false, data: null});
      return;
    }
  }

  private subscribeRouteParams(): void {
    // see here why this must be done this way: https://github.com/angular/angular/issues/11023
    this.addSubscription(
      'internal.context.router.event-id',
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .pipe(
          tap(() => {
            // keep in mind that this event can and will fire not only once, but possibly n times per route change
            let route = this.route;
            while (route.firstChild) {
              route = route.firstChild;
            }

            const eventId = route.snapshot.paramMap.get(ContextService.eventIdentifierName);

            this.fireIsEventRelatedPage(!!eventId);
            this.subscribeEvent(eventId);
          }),
        )
        .subscribe(),
    );
  }

  private fireIsEventRelatedPage(value: boolean) {
    if (this.isEventRelatedPage.value !== value) {
      this.isEventRelatedPage.next(value);
    }
  }

  private subscribeEvent(eventId: string) {
    this.event.next({...this.event.value, loading: true});

    this.addSubscription(
      EVENT_SUBSCRIPTION_KEY,
      this.events
        .pipe(
          filter(({loading}) => !loading),
          pluck('data'),
          map((events) => events.find((event) => event.id === eventId)),
          tap((data) => this.event.next({...this.event.value, loading: false, data})),
          tap((event) => {
            if (!event) {
              this.eventContextService.clearSubscriptions();
              return;
            }

            this.eventContextService.refreshSubscriptions(event?.id, this.currentLocale.application);
          }),
        )
        .subscribe({error: (error) => this.subscriptionErrorHandler(EVENT_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeEvents(): void {
    this.events.next({...this.events.value, loading: true});

    this.addSubscription(
      EVENTS_SUBSCRIPTION_KEY,
      this.eventRepository
        .getEventsByLocale(this.currentLocale.application)
        .pipe(tap((data) => this.events.next({...this.events.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(EVENTS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeEventTypes(): void {
    this.eventTypes.next({...this.eventTypes.value, loading: true});

    this.addSubscription(
      EVENT_TYPES_SUBSCRIPTION_KEY,
      this.eventTypeRepository
        .getEventTypesByLocale(this.currentLocale.application)
        .pipe(tap((data) => this.eventTypes.next({...this.eventTypes.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(EVENT_TYPES_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeAppNews(): void {
    this.appNews.next({...this.appNews.value, loading: !this.appNews.value.data?.length});

    this.addSubscription(
      APP_NEWS_SUBSCRIPTION_KEY,
      this.newsRepository
        .getAllPublished()
        .pipe(tap((data) => this.appNews.next({...this.appNews.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(APP_NEWS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeEventNews(): void {
    this.eventNews.next({...this.eventNews.value, loading: !this.eventNews.value.data?.length});

    this.addSubscription(
      INTERNAL_EVENT_NEWS_SUBSCRIPTION_KEY,
      this.event
        .pipe(
          filter(({loading}) => !loading),
          pluck('data'),
          tap((event) => {
            if (event) {
              this.updateEventNews(event.eventType);
              return;
            }

            this.eventNews.next({...this.eventNews.value, loading: false, data: null});
          }),
        )
        .subscribe({error: (error) => this.subscriptionErrorHandler(INTERNAL_EVENT_NEWS_SUBSCRIPTION_KEY, error)}),
    );
  }
  private updateEventNews(eventType: string): void {
    this.addSubscription(
      EVENT_NEWS_SUBSCRIPTION_KEY,
      this.newsRepository
        .getAllPublishedByEventType(eventType)
        .pipe(tap((data) => this.eventNews.next({...this.eventNews.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(EVENT_NEWS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeClassifieds(): void {
    this.classifieds.next({...this.classifieds.value, loading: !this.classifieds.value.data?.length});

    this.addSubscription(
      CLASSIFIEDS_SUBSCRIPTION_KEY,
      this.classifiedsRepository
        .getAllByLocale(this.currentLocale.application, 'name')
        .pipe(tap((data) => this.classifieds.next({...this.classifieds.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(CLASSIFIEDS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscribeInterests(): void {
    this.interests.next({...this.interests.value, loading: !this.interests.value.data?.length});

    this.addSubscription(
      INTERESTS_SUBSCRIPTION_KEY,
      this.interestsRepository
        .getAllByLocale(this.currentLocale.application, 'name')
        .pipe(tap((data) => this.interests.next({...this.interests.value, loading: false, data})))
        .subscribe({error: (error) => this.subscriptionErrorHandler(INTERESTS_SUBSCRIPTION_KEY, error)}),
    );
  }

  private subscriptionErrorHandler(source: string, error: any): void {
    this.logger.error(source, error);
  }
}
