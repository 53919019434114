import {LocalizedPartnerEntity} from '../partner-legacy/localized-partner.entity';
import {LocalePartnerCategoryEntity} from './locale-partner-category.entity';
import {MetadataPartnerCategoryEntity} from './metadata-partner-category.entity';
import {PartnerCategoryExternalIds} from './partner-category.interface';

export class LocalizedPartnerCategoryEntity {
  public constructor(
    private _id: string = '',
    private metadata: MetadataPartnerCategoryEntity,
    private locale: LocalePartnerCategoryEntity,
    private _partners: LocalizedPartnerEntity[] = [],
  ) {}

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this.locale.name;
  }

  public get eventReference(): string {
    return this.metadata.eventReference;
  }

  public get isActive(): boolean {
    return this.metadata.isActive;
  }

  public get sortOrder(): number {
    return this.metadata.sortOrder;
  }

  public get externalIds(): PartnerCategoryExternalIds {
    return this.metadata.externalIds;
  }

  public get partners(): LocalizedPartnerEntity[] {
    return this._partners;
  }

  public set partners(partners: LocalizedPartnerEntity[]) {
    this._partners = partners;
  }

  public getLocalizedContentEntity(): LocalePartnerCategoryEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataPartnerCategoryEntity {
    return this.metadata;
  }
}
