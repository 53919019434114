import {Injectable, OnDestroy} from '@angular/core';
import {filter, pluck, tap} from 'rxjs/operators';

import {SubscriptionManager} from '@semabit/nzz-connect-common-ng/components/context/subscription-manager';
import {LocalizedEventEntity} from '@semabit/nzz-connect-common-ng/entities/event-legacy/localized-event.entity';
import {Identity} from '@semabit/nzz-connect-common-ng/entities/identity.interface';
import {UserEntity} from '@semabit/nzz-connect-common-ng/entities/user/user.entity';
import {LoggerService} from '@semabit/nzz-connect-common-ng/services/logging/logger.service';

import {DEFAULT_PAGE_SIZE_OPTIONS} from '@app/models/pagination';
import {ContextService} from '@app/modules/shared-components/services/context.service';

const EVENT_SUBSCRIPTION_KEY = 'abstract-context-aware.event';
const EVENT_RELATED_PAGE_SUBSCRIPTION_KEY = 'abstract-context-aware.is-event-related-page';

@Injectable()
export abstract class AbstractContextAwareComponent extends SubscriptionManager implements OnDestroy {
  protected readonly DEFAULT_PAGE_SIZE_OPTIONS = DEFAULT_PAGE_SIZE_OPTIONS;

  public user: UserEntity;
  public isEventRelatedPage = false;
  public event: LocalizedEventEntity;

  protected constructor(protected logger: LoggerService, protected contextService: ContextService) {
    super(logger);

    this.makeSubscriptions();
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public trackByFn(index: number, item: Identity): string {
    return item.id;
  }

  public trackByIndex(index: number) {
    return index;
  }

  protected makeSubscriptions(): void {
    this.addSubscription(
      EVENT_SUBSCRIPTION_KEY,
      this.contextService.event
        .pipe(
          filter(({loading}) => !loading),
          pluck('data'),
          tap((event) => (this.event = event)),
          tap((event) => this.notifyEventChange(event)),
        )
        .subscribe(),
    );

    this.addSubscription(
      EVENT_RELATED_PAGE_SUBSCRIPTION_KEY,
      this.contextService.isEventRelatedPage
        .pipe(
          tap((isEventRelatedPage) => (this.isEventRelatedPage = isEventRelatedPage)),
          tap(() => this.notifyEventChange(this.contextService.event.value.data)),
        )
        .subscribe(),
    );
  }

  private notifyEventChange(event: LocalizedEventEntity): void {
    if (this.getSubscription(EVENT_SUBSCRIPTION_KEY)) {
      this.eventChanged(event);
    }
  }

  protected eventChanged(_event: LocalizedEventEntity): void {}
}
