import {SpeakerDocumentI18n} from '../entities/speaker/speaker-document.interface';
import {SpeakerExternalIds} from '../entities/speaker-legacy/speaker.interface';

export class SpeakerHelper {
  public static initExternalIds({
    amadeusId = '',
    nzzConnectCmsId = '',
  }: {
    amadeusId?: string;
    nzzConnectCmsId?: string;
  } = {}): SpeakerExternalIds {
    return {amadeusId, nzzConnectCmsId};
  }

  public static initI18n({job = '', description = '', descriptionMarkdown = ''}: Partial<SpeakerDocumentI18n> = {}): SpeakerDocumentI18n {
    return {job, description, descriptionMarkdown};
  }
}
