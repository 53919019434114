import {PartnerDocumentI18n} from '../entities/partner/partner-document.interface';
import {PartnerExternalIds} from '../entities/partner-legacy/partner.interface';

export class PartnerHelper {
  public static initExternalIds(amadeusId = '', nzzConnectCmsId = ''): PartnerExternalIds {
    return {amadeusId, nzzConnectCmsId};
  }

  public static initI18n({description = '', descriptionMarkdown = '', url = ''}: Partial<PartnerDocumentI18n> = {}): PartnerDocumentI18n {
    return {description, descriptionMarkdown, url};
  }
}
