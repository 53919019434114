import {Component} from '@angular/core';

import {LoggerService} from '@semabit/nzz-connect-common-ng/services/logging/logger.service';

import {AbstractContextAwareComponent} from '@app/modules/shared-components/components/abstract.context-aware.component';
import {ContextService} from '@app/modules/shared-components/services/context.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent extends AbstractContextAwareComponent {
  public constructor(contextService: ContextService, logger: LoggerService) {
    super(logger, contextService);
  }
}
