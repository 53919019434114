import {Locale} from '../../interfaces/environment.interface';
import {EventAward, LocaleEventAward} from '../../interfaces/event-award.interface';
import {FirestoreTimestampType} from '../../interfaces/firebase';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {Dates} from '../dates';
import {I18nDocument} from '../i18n-document.interface';
import {LocaleEntityType} from '../locale-entity.type';

export type EventTimeFrame<DATE extends Date | FirestoreTimestampType> = Record<'start' | 'end', DATE>;

export enum EventStatus {
  DRAFT = 'draft',
  PREANNOUCEMENT = 'preannouncement',
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export enum EventParticipantImportMode {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum EventParticipantImportSystem {
  AMADEUS = 'amadeus',
  COLADA = 'colada',
}

export type EventParticipantImportSystems = typeof EventParticipantImportSystem[keyof typeof EventParticipantImportSystem];

type EventDates<DATE extends Date | FirestoreTimestampType> = Pick<Dates<DATE>, 'updatedAt'>;

export interface EventDocumentMeta<DATE extends Date | FirestoreTimestampType> extends EventDates<DATE> {
  amadeusId: string;
  times: EventTimeFrame<DATE>[];
  wifiSsid: string;
  wifiPw: string;
  hashtag: string;
  status: EventStatus;
  eventType: string;
  background: Image;
  backgroundOverlay: Image;
  logo: Image;
  backgroundColor: string;
  backgroundOppositeColor: string;
  accentColor: string;
  accentOppositeColor: string;
  newsUrl: string;
  bottomNavigationLabel: string;
  votingEnabled: boolean;
  questionEnabled: boolean;
  supportedLanguages: Locale[];
  fallbackLanguage: Locale;
  award: EventAward;
  livestreamUrl: string;
  isLivestreamEnabled: boolean;
  isLivestreamPublic: boolean;
  teaser: Image;
  participantImportMode: EventParticipantImportMode;
  isParticipantImportAllowed: boolean;
  socialMedia: SocialMedia;
  coladaId: string;
  participantImportSystem: EventParticipantImportSystem;
  nzzConnectCmsId: string;
}

export type EventDocumentMetaWithDate = EventDocumentMeta<Date>;
export type EventDocumentMetaWithTimestamp = EventDocumentMeta<FirestoreTimestampType>;

export interface EventDocument extends EventDocumentMeta<FirestoreTimestampType>, I18nDocument<EventDocumentI18n> {}

export interface EventDocumentI18n {
  name: string;
  location: string;
  description: string;
  descriptionMarkdown: string;
  languages: string;
  participants: string;
  price: string;
  registrationLabel: string;
  registrationLink: string;
  speakers: string;
  additionalLink: string;
  additionalLabel: string;
  locationLink: string;
  award: LocaleEventAward;
}

export type EventTranslations = LocaleEntityType<EventDocumentI18n>;
