import {ImageHelper} from '../../helpers/image.helper';
import {SocialMediaHelper} from '../../helpers/social-media.helper';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {Identity} from '../identity.interface';
import {AwardFinalistDocumentMetaWithDate} from './award-finalist-document.interface';

export class AwardFinalistMetaModel implements AwardFinalistDocumentMetaWithDate, Identity {
  public constructor(
    public id: string = '',
    public eventId: string = '',
    public name: string = '',
    public image: Image = ImageHelper.init(),
    public socialMedia: SocialMedia = SocialMediaHelper.init(),
    public updatedAt: Date = new Date(),
  ) {}
}
