export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

interface SortOptions {
  ignoreCase?: boolean;
  order: SortDirection;
}

const DEFAULT_SORT_OPTIONS: SortOptions = {ignoreCase: true, order: SortDirection.ASC} as const;

export const sortAlphabetically = (a: string, b: string, options: SortOptions = DEFAULT_SORT_OPTIONS): number => {
  if (!b && !a) {
    return 0;
  }

  if (a && !b) {
    return 1;
  }

  if (b && !a) {
    return -1;
  }

  if (options.ignoreCase) {
    a = a.toLowerCase();
  }

  if (options.ignoreCase) {
    b = b.toLowerCase();
  }

  const sortOrder = options.order === 'asc' ? 1 : -1;

  if (a < b) {
    return -1 * sortOrder;
  }

  if (a > b) {
    return sortOrder;
  }

  return 0;
};

export const sortNumerically = (a?: number, b?: number): number => {
  return (a || 0) - (b || 0);
};

export const sortChronologically = (a: string | Date, b: string | Date, {order = DEFAULT_SORT_OPTIONS.order} = {}): number => {
  return (new Date(a).getTime() - new Date(b).getTime()) * (order === 'asc' ? 1 : -1);
};

export const sortNaturally = (a: string, b: string) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'});
