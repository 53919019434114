import {FirestoreTimestampType} from '../../interfaces/firebase';
import {Dates} from '../dates';
import {I18nDocument} from '../i18n-document.interface';
import {LocaleEntityType} from '../locale-entity.type';

export enum VotingStatus {
  DRAFT = 'draft',
  TESTING = 'testing',
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum VotingType {
  GLOBAL = 'global',
}

type VotingDates<DATE extends Date | FirestoreTimestampType> = Pick<Dates<DATE>, 'updatedAt'>;

export interface VotingDocumentMeta<DATE extends Date | FirestoreTimestampType> extends VotingDates<DATE> {
  eventId: string;
  session: string | VotingType; // session-id
  status: VotingStatus;
  chartType: string;
  result: number[];
}

export type VotingDocumentMetaWithDate = VotingDocumentMeta<Date>;

export interface VotingDocument extends VotingDocumentMeta<FirestoreTimestampType>, I18nDocument<VotingDocumentI18n> {}

export interface VotingDocumentI18n {
  question: string;
  choices: string[];
}

export type VotingTranslations = LocaleEntityType<VotingDocumentI18n>;
