import {ImageHelper} from '../../helpers/image.helper';
import {SocialMediaHelper} from '../../helpers/social-media.helper';
import {Locale} from '../../interfaces/environment.interface';
import {EventAward} from '../../interfaces/event-award.interface';
import {Image} from '../../interfaces/image.interface';
import {SocialMedia} from '../../interfaces/social-media.interface';
import {EventParticipantImportMode, EventParticipantImportSystem, EventStatus} from '../event/event-document.interface';

export class MetadataEventEntity {
  public constructor(
    private _amadeusId: string,
    private _times: any[],
    private _wifiSsid: string,
    private _wifiPw: string,
    private _hashtag: string,
    private _status: string,
    private _eventType: string,
    private _background: Image = ImageHelper.init(),
    private _backgroundOverlay: Image = ImageHelper.init(),
    private _logo: Image = ImageHelper.init(),
    private _backgroundColor: string,
    public backgroundOppositeColor: string,
    private _accentColor: string,
    private _accentOppositeColor: string,
    private _newsUrl: string,
    private _bottomNavigationLabel: string,
    private _votingEnabled: boolean,
    private _questionEnabled: boolean,
    private _supportedLanguages: Locale[],
    private _fallbackLanguage: Locale,
    private _award: EventAward,
    private _livestreamUrl: string,
    private _isLivestreamEnabled: boolean,
    private _isLivestreamPublic: boolean,
    public teaser: Image = ImageHelper.init(),
    public participantImportMode: EventParticipantImportMode = EventParticipantImportMode.MANUAL,
    public isParticipantImportAllowed: boolean = false,
    public socialMedia: SocialMedia = SocialMediaHelper.init(),
    public coladaId = '',
    public participantImportSystem: EventParticipantImportSystem = EventParticipantImportSystem.ARMIDA,
    public nzzConnectCmsId = '',
    public armidaId = '',
  ) {}

  public get background(): Image {
    return this._background;
  }

  public set background(value: Image) {
    this._background = value;
  }

  public get backgroundOverlay(): Image {
    return this._backgroundOverlay;
  }

  public set backgroundOverlay(value: Image) {
    this._backgroundOverlay = value;
  }

  public get logo(): Image {
    return this._logo;
  }

  public set logo(value: Image) {
    this._logo = value;
  }

  public get backgroundColor(): string {
    return this._backgroundColor;
  }

  public set backgroundColor(value: string) {
    this._backgroundColor = value;
  }

  public get accentColor(): string {
    return this._accentColor;
  }

  public set accentColor(value: string) {
    this._accentColor = value;
  }

  public get accentOppositeColor(): string {
    return this._accentOppositeColor;
  }

  public set accentOppositeColor(value: string) {
    this._accentOppositeColor = value;
  }

  public get newsUrl(): string {
    return this._newsUrl;
  }

  public set newsUrl(value: string) {
    this._newsUrl = value;
  }

  public get times(): any[] {
    return this._times;
  }

  public set times(value: any[]) {
    this._times = value;
    this.sortTimes();
  }

  public get startDate(): Date {
    return this.times[0].start;
  }

  public get endDate(): Date {
    return this.times[this.times.length - 1].end;
  }

  public get eventDurationIsLongerThanOneDay(): boolean {
    const startDate = new Date(this.startDate.getTime());
    const endDate = new Date(this.endDate.getTime());
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    return startDate.getTime() !== endDate.getTime();
  }

  public get wifiSsid(): string {
    return this._wifiSsid;
  }

  public set wifiSsid(value: string) {
    this._wifiSsid = value;
  }

  public get wifiPw(): string {
    return this._wifiPw;
  }

  public set wifiPw(value: string) {
    this._wifiPw = value;
  }

  public get hashtag(): string {
    return this._hashtag;
  }

  public set hashtag(value: string) {
    this._hashtag = value;
  }

  public get status(): string {
    return this._status;
  }

  public set status(value: string) {
    this._status = value;
  }

  public get amadeusId(): string {
    return this._amadeusId;
  }

  public set amadeusId(value: string) {
    this._amadeusId = value;
  }

  public get eventType(): string {
    return this._eventType;
  }

  public set eventType(value: string) {
    this._eventType = value;
  }

  public get bottomNavigationLabel(): string {
    return this._bottomNavigationLabel;
  }

  public set bottomNavigationLabel(value: string) {
    this._bottomNavigationLabel = value;
  }

  public get isVotingEnabled(): boolean {
    return this._votingEnabled;
  }

  public set isVotingEnabled(value: boolean) {
    this._votingEnabled = value;
  }

  public get isQuestionEnabled(): boolean {
    return this._questionEnabled;
  }

  public set isQuestionEnabled(value: boolean) {
    this._questionEnabled = value;
  }

  public get supportedLanguages(): Locale[] {
    return this._supportedLanguages;
  }

  public set supportedLanguages(value: Locale[]) {
    this._supportedLanguages = value;
  }

  public get fallbackLanguage(): Locale {
    return this._fallbackLanguage;
  }

  public set fallbackLanguage(value: Locale) {
    this._fallbackLanguage = value;
  }

  public get award(): EventAward {
    return this._award;
  }

  public set award(value: EventAward) {
    this._award = value;
  }

  public get livestreamUrl(): string {
    return this._livestreamUrl;
  }

  public set livestreamUrl(value: string) {
    this._livestreamUrl = value;
  }

  public get isLivestreamEnabled(): boolean {
    return this._isLivestreamEnabled;
  }

  public set isLivestreamEnabled(value: boolean) {
    this._isLivestreamEnabled = value;
  }

  public get isLivestreamPublic(): boolean {
    return this._isLivestreamPublic;
  }

  public set isLivestreamPublic(value: boolean) {
    this._isLivestreamPublic = value;
  }

  public get isDraft(): boolean {
    return this._status === EventStatus.DRAFT;
  }

  public get isPreannouncement(): boolean {
    return this._status === EventStatus.PREANNOUCEMENT;
  }

  public get isActive(): boolean {
    return this._status === EventStatus.ACTIVE;
  }

  public get isArchived(): boolean {
    return this._status === EventStatus.ARCHIVE;
  }

  public get isManualParticipantImport(): boolean {
    return this.participantImportMode === EventParticipantImportMode.MANUAL;
  }

  public get isAutomaticParticipantImport(): boolean {
    return this.participantImportMode === EventParticipantImportMode.AUTOMATIC;
  }

  public get isAutomaticAmadeusParticipantImport(): boolean {
    return (
      this.participantImportMode === EventParticipantImportMode.AUTOMATIC
      && this.participantImportSystem === EventParticipantImportSystem.AMADEUS
    );
  }

  public get isAutomaticColadaParticipantImport(): boolean {
    return (
      this.participantImportMode === EventParticipantImportMode.AUTOMATIC
      && this.participantImportSystem === EventParticipantImportSystem.COLADA
    );
  }

  public get isAutomaticArmidaParticipantImport(): boolean {
    return (
      this.participantImportMode === EventParticipantImportMode.AUTOMATIC
      && this.participantImportSystem === EventParticipantImportSystem.ARMIDA
    );
  }

  public get hasSocialMedia(): boolean {
    return Object.values(this.socialMedia).some((x) => !!x);
  }

  public isLanguageSupported(locale: Locale): boolean {
    return this._supportedLanguages.includes(locale);
  }

  private sortTimes(): void {
    if (this.times) {
      this.times.sort((timeA, timeB) => timeA.start - timeB.start);
    }
  }
}
