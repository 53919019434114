import {Locale} from '../../interfaces/environment.interface';
import {EventAward, LocaleEventAward} from '../../interfaces/event-award.interface';
import {Image} from '../../interfaces/image.interface';
import {UserEntity} from '../user/user.entity';
import {LocaleEventEntity} from './locale-event.entity';
import {MetadataEventEntity} from './metadata-event.entity';

export class LocalizedEventEntity {
  public constructor(private _id: string = '', private metadata: MetadataEventEntity, private locale: LocaleEventEntity) {}

  public get name(): string {
    return this.locale.name;
  }

  public get location(): string {
    return this.locale.location;
  }

  public get speakers(): string {
    return this.locale.speakers;
  }

  public get registrationLink(): string {
    return this.locale.registrationLink;
  }

  public get registrationLabel() {
    return this.locale.registrationLabel;
  }

  public get price(): string {
    return this.locale.price;
  }

  public get participants(): string {
    return this.locale.participants;
  }

  public get languages(): string {
    return this.locale.languages;
  }

  public get description(): string {
    return this.locale.description;
  }

  public get descriptionMarkdown(): string {
    return this.locale.descriptionMarkdown;
  }

  public get times(): any[] {
    return this.metadata.times;
  }

  /**
   * Determine if event is happening in the future (first start time in future).
   */
  public get isInFuture(): boolean {
    if (this.metadata.times.length === 0) {
      return false;
    }
    return this.times[0].start > new Date();
  }

  public get isToday(): boolean {
    if (this.metadata.times.length === 0) {
      return false;
    }

    let isToday = false;
    const today = new Date().setHours(0, 0, 0, 0);

    for (const time of this.metadata.times) {
      const tmpDate = new Date(time.start).setHours(0, 0, 0, 0);
      if (tmpDate === today) {
        isToday = true;
      }
    }

    return isToday;
  }

  public get wifiSsid(): string {
    return this.metadata.wifiSsid;
  }

  public get wifiPw(): string {
    return this.metadata.wifiPw;
  }

  public get hashtag(): string {
    return this.metadata.hashtag;
  }

  public get status(): string {
    return this.metadata.status;
  }

  public get id(): string {
    return this._id;
  }

  public get amadeusId(): string {
    return this.metadata.amadeusId;
  }

  public get eventType(): string {
    return this.metadata.eventType;
  }

  public get background(): Image {
    return this.metadata.background;
  }

  public get backgroundOverlay(): Image {
    return this.metadata.backgroundOverlay;
  }

  public get logo(): Image {
    return this.metadata.logo;
  }

  public get backgroundColor(): string {
    return this.metadata.backgroundColor;
  }

  public get backgroundOppositeColor(): string {
    return this.metadata.backgroundOppositeColor;
  }

  public get accentColor(): string {
    return this.metadata.accentColor;
  }

  public get accentOppositeColor(): string {
    return this.metadata.accentOppositeColor;
  }

  public get newsUrl(): string {
    return this.metadata.newsUrl;
  }

  public get endDate(): Date {
    return this.metadata.endDate;
  }

  public get startDate(): Date {
    return this.metadata.startDate;
  }

  public get additionalLink(): string {
    return this.locale.additionalLink;
  }

  public get additionalLabel(): string {
    return this.locale.additionalLabel;
  }

  public get hasAdditionalLink(): boolean {
    return this.locale.hasAdditionalLink;
  }

  public get locationLink(): string {
    return this.locale.locationLink;
  }

  public get bottomNavigationLabel(): string {
    return this.metadata.bottomNavigationLabel;
  }

  public get isVotingEnabled(): boolean {
    return this.metadata.isVotingEnabled;
  }

  public get isQuestionEnabled(): boolean {
    return this.metadata.isQuestionEnabled;
  }

  public get supportedLanguages(): Locale[] {
    return this.metadata.supportedLanguages;
  }

  public get fallbackLanguage(): Locale {
    return this.metadata.fallbackLanguage;
  }

  public get award(): EventAward & LocaleEventAward {
    return {...this.metadata.award, ...this.locale.award};
  }

  public get livestreamUrl() {
    return this.metadata.livestreamUrl;
  }

  public get isLivestreamEnabled() {
    return this.metadata.isLivestreamEnabled;
  }

  public get isLivestreamPublic() {
    return this.metadata.isLivestreamPublic;
  }

  public get isDraft(): boolean {
    return this.metadata.isDraft;
  }

  public get isPreannouncement(): boolean {
    return this.metadata.isPreannouncement;
  }

  public get isActive(): boolean {
    return this.metadata.isActive;
  }

  public get isArchived(): boolean {
    return this.metadata.isArchived;
  }

  public isLanguageSupported(locale: Locale): boolean {
    return this.metadata.isLanguageSupported(locale);
  }

  public isLivestreamAvailable(user: UserEntity): boolean {
    return this.isLivestreamEnabled && !!this.livestreamUrl && (this.isLivestreamPublic || user?.hasEventWithId(this.id));
  }

  public get teaser(): MetadataEventEntity['teaser'] {
    return this.metadata.teaser;
  }

  public get participantImportMode(): MetadataEventEntity['participantImportMode'] {
    return this.metadata.participantImportMode;
  }

  public get isManualParticipantImport(): boolean {
    return this.metadata.isManualParticipantImport;
  }

  public get isAutomaticParticipantImport(): boolean {
    return this.metadata.isAutomaticParticipantImport;
  }

  public get isAutomaticAmadeusParticipantImport(): boolean {
    return this.metadata.isAutomaticAmadeusParticipantImport;
  }

  public get isAutomaticColadaParticipantImport(): boolean {
    return this.metadata.isAutomaticColadaParticipantImport;
  }

  public get isParticipantImportAllowed(): MetadataEventEntity['isParticipantImportAllowed'] {
    return this.metadata.isParticipantImportAllowed;
  }

  public get socialMedia(): MetadataEventEntity['socialMedia'] {
    return this.metadata.socialMedia;
  }

  public get hasSocialMedia(): MetadataEventEntity['hasSocialMedia'] {
    return this.metadata.hasSocialMedia;
  }

  public get coladaId(): string {
    return this.metadata.coladaId;
  }

  public get participantImportSystem(): MetadataEventEntity['participantImportSystem'] {
    return this.metadata.participantImportSystem;
  }

  public get nzzConnectCmsId(): string {
    return this.metadata.nzzConnectCmsId;
  }

  public get armidaId(): string {
    return this.metadata.armidaId;
  }

  public getLocalizedContentEntity(): LocaleEventEntity {
    return this.locale;
  }

  public getMetadataEntity(): MetadataEventEntity {
    return this.metadata;
  }
}
