import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LocaleEntityType} from '../entities/locale-entity.type';
import {SessionDocumentMetaWithDate} from '../entities/session/session-document.interface';
import {LocaleSessionEntity} from '../entities/session-legacy/locale-session.entity';
import {LocalizedSessionEntity} from '../entities/session-legacy/localized-session.entity';
import {MetadataSessionEntity} from '../entities/session-legacy/metadata-session.entity';
import {SessionLegacyEntity} from '../entities/session-legacy/session.legacy.entity';
import {UserEntity} from '../entities/user/user.entity';
import {ImageHelper} from '../helpers/image.helper';
import {SessionHelper} from '../helpers/session.helper';
import {EnvironmentLocales, Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';

@Injectable({providedIn: 'root'})
export class SessionLegacyRepository extends AbstractRepository {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'sessions');
  }

  public async saveSession(metadataSession: MetadataSessionEntity, localizedDataArray: LocaleSessionEntity[]): Promise<DocumentReference> {
    const sessionCollection = await this.angularFirestore.collection<SessionDocumentMetaWithDate>(this.collectionName);
    const i18nCollection = await this.angularFirestore.collection('i18n');

    const newDocument = sessionCollection.doc();

    const i18nPromises = localizedDataArray.map((localizedData) =>
      i18nCollection.doc(localizedData.locale).collection(this.collectionName).doc(newDocument.ref.id).set({
        session: newDocument.ref.path,
        description: localizedData.description,
        descriptionMarkdown: localizedData.descriptionMarkdown,
        location: localizedData.location,
        name: localizedData.name,
        award: localizedData.award,
      }),
    );

    return Promise.all(i18nPromises)
      .then(() =>
        newDocument.set(
          {
            amadeusId: metadataSession.amadeusId,
            event: metadataSession.eventReference,
            language: metadataSession.language,
            start: metadataSession.start,
            end: metadataSession.end,
            speakers: metadataSession.speakers,
            image: metadataSession.image,
            isOptional: metadataSession.isOptional,
            isPrivate: metadataSession.isPrivate,
            isAskingAllowed: metadataSession.isAskingAllowed,
            isQuestionRoundClosed: metadataSession.isQuestionRoundClosed,
            hideSpeakersInList: metadataSession.hideSpeakersInList,
            type: metadataSession.type,
            roomId: metadataSession.roomId,
            awardFinalists: metadataSession.awardFinalists,
            isRatingAllowed: metadataSession.isRatingAllowed,
            isRatingRoundClosed: metadataSession.isRatingRoundClosed,
            coladaId: metadataSession.coladaId,
            updatedAt: new Date(),
          },
          {merge: true},
        ),
      )
      .then(() => newDocument.ref);
  }

  /**
   * Get all sessions as array of SessionEntity
   */
  public getSessionsByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedSessionEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) =>
          ref.where('event', '==', 'event' + '/' + eventId).orderBy('start', 'asc'),
        ),
      ),
      this.collectionName,
      'session',
      locale,
    ).pipe(
      map((sessions) => {
        const mappedSessions = sessions.map((session) => this.createEntityFromResponseWithLocale(session));
        // sort by start and name
        mappedSessions.sort((a, b) => {
          if (a.start.getTime() === b.start.getTime()) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          }
          return a.start.getTime() > b.start.getTime() ? 1 : -1;
        });

        return mappedSessions;
      }),
    );
  }

  public getSessionsByEventAndForParticipant(
    eventId: string,
    user: UserEntity,
    locale: Locale = Locale.DE,
  ): Observable<LocalizedSessionEntity[]> {
    return this.getSessionsByEventAndLocale(eventId, locale).pipe(
      map((sessions) => {
        if (typeof user.sessionIds !== 'undefined') {
          return sessions.filter((session) => user.sessionIds.includes(session.id));
        }
        return [];
      }),
    );
  }

  /**
   * Get all sessions as array of SessionEntity
   */
  public getSessionsBySpeakerAndLocale(eventId: string, speakerId: string, locale: Locale = Locale.DE): Observable<any> {
    return this.getSessionsByEventAndLocale(eventId, locale).pipe(
      map((sessions) => {
        const data = [];
        for (const session of sessions) {
          const sessionEntity = this.createEntityFromResponseWithLocale(session);
          if (sessionEntity.speakers) {
            if ((sessionEntity.speakers as Array<any>).indexOf(speakerId) > -1) {
              data.push(sessionEntity);
            }
          }
        }
        return data;
      }),
    );
  }

  /**
   * Return session by document key with localized content
   */
  public getSessionByKeyAndLocale(key: string, locale: Locale = Locale.DE): Observable<LocalizedSessionEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'session',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  /**
   * Return session by document key with localized contents
   */
  public getSessionByKeyWithLocalizedContent(key: string, locales: any[]): Observable<any> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'session',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  public getSessionsByRoomWithLocalizedContent(
    roomId: string,
    locales: any | Locale | EnvironmentLocales,
  ): Observable<SessionLegacyEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(this.angularFirestore.collection(this.collectionName, (ref) => ref.where('roomId', '==', roomId))),
      this.collectionName,
      'session',
      locales,
    ).pipe(map((sessions) => sessions.map((session) => this.createEntityFromResponseWithLocales(session))));
  }

  public getSessionsByAwardFinalistsWithLocalizedContent(
    awardFinalist: string,
    locales: any | Locale | EnvironmentLocales,
  ): Observable<SessionLegacyEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) => ref.where('awardFinalists', 'array-contains', awardFinalist)),
      ),
      this.collectionName,
      'session',
      locales,
    ).pipe(map((sessions) => sessions.map((session) => this.createEntityFromResponseWithLocales(session))));
  }

  public async updateSession(sessionEntity: SessionLegacyEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, sessionEntity.getLocalizedContentEntity(localeKey).id, {
        description: sessionEntity.getDescription(localeKey),
        descriptionMarkdown: sessionEntity.getDescriptionMarkdown(localeKey),
        name: sessionEntity.getName(localeKey),
        location: sessionEntity.getLocation(localeKey),
        award: sessionEntity.getLocaleAward(localeKey),
      });
    }

    return this.updateDocument(sessionEntity.getId(), {
      amadeusId: sessionEntity.getAmadeusId(),
      start: sessionEntity.getStart(),
      end: sessionEntity.getEnd(),
      language: sessionEntity.getLanguage(),
      speakers: sessionEntity.getSpeakers(),
      image: sessionEntity.getImage(),
      isOptional: sessionEntity.isOptional(),
      isPrivate: sessionEntity.isPrivate(),
      isAskingAllowed: sessionEntity.isAskingAllowed(),
      isQuestionRoundClosed: sessionEntity.isQuestionRoundClosed(),
      hideSpeakersInList: sessionEntity.hideSpeakersInList(),
      type: sessionEntity.getType(),
      roomId: sessionEntity.getRoomId(),
      awardFinalists: sessionEntity.getAwardFinalists(),
      isRatingAllowed: sessionEntity.isRatingAllowed(),
      isRatingRoundClosed: sessionEntity.isRatingRoundClosed(),
      coladaId: sessionEntity.getColadaId(),
      updatedAt: new Date(),
    });
  }

  /**
   * Create entity from response with locale
   */
  public createEntityFromResponseWithLocale(response, locale: Locale = Locale.DE) {
    const localizedContent = new LocaleSessionEntity(
      response.id,
      locale,
      response.description,
      response.descriptionMarkdown === undefined ? response.description : response.descriptionMarkdown,
      response.name,
      response.location,
      response.award || SessionHelper.initLocaleSessionAward(),
    );

    const metadataEventEntity = this.getMetaEntityFromResponse(response);

    return new LocalizedSessionEntity(response.id, metadataEventEntity, localizedContent);
  }

  /**
   * Delete a session.
   */
  public async delete(id: string, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.deleteI18nDocument(this.collectionName, id, 'session', localeKey);
    }

    return await this.deleteDocument(this.collectionName, id);
  }

  public removeRoomFromSessions(roomId: string) {
    return this.updateDocumentsByRefField(this.collectionName, 'roomId', roomId, '');
  }

  public removeAwardFinalistsFromSessions(awardFinalist: string) {
    return this.removeValueFromArrayInDocuments(this.collectionName, 'awardFinalists', awardFinalist);
  }

  private getMetaEntityFromResponse(response): MetadataSessionEntity {
    return new MetadataSessionEntity(
      response.amadeusId !== undefined ? response.amadeusId : '',
      response.start.toDate(),
      response.end.toDate(),
      response.event,
      response.language,
      response.speakers || [],
      response.image || ImageHelper.init(),
      response.isOptional !== undefined ? response.isOptional : false,
      response.isPrivate !== undefined ? response.isPrivate : false,
      response.isAskingAllowed !== undefined ? response.isAskingAllowed : false,
      response.isQuestionRoundClosed !== undefined ? response.isQuestionRoundClosed : true,
      response.hideSpeakersInList !== undefined ? response.hideSpeakersInList : false,
      response.type !== undefined ? response.type : MetadataSessionEntity.TYPE_REGULAR,
      response.roomId || '',
      response.awardFinalists || [],
      response.isRatingAllowed !== undefined ? response.isRatingAllowed : false,
      response.isRatingRoundClosed !== undefined ? response.isRatingRoundClosed : false,
      response.coladaId || '',
    );
  }

  /**
   * Create entity from response
   */
  private createEntityFromResponseWithLocales(response): SessionLegacyEntity {
    const localizedEntities = {} as LocaleEntityType<LocaleSessionEntity>;

    for (const key of Object.keys(response.localized)) {
      const descriptionMarkdown =
        response.localized[key].descriptionMarkdown === undefined
          ? response.localized[key].description
          : response.localized[key].descriptionMarkdown;

      localizedEntities[key] = new LocaleSessionEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].description,
        descriptionMarkdown,
        response.localized[key].name,
        response.localized[key].location,
        response.localized[key].award || SessionHelper.initLocaleSessionAward(),
      );
    }

    const metadataEventEntity = this.getMetaEntityFromResponse(response);

    return new SessionLegacyEntity(response.id, metadataEventEntity, localizedEntities);
  }
}
