import {EventParticipantImportSystem} from '../entities/event/event-document.interface';
import {
  ParticipantApp,
  ParticipantAppChatSettings,
  ParticipantAppSettings,
  ParticipantContactInformation,
} from '../entities/participant/participant.interface';
import {UserExternalIds} from '../entities/user/user.interface';
import {SocialMediaHelper} from './social-media.helper';

export class ParticipantHelper {
  public static initContactInformation(
    email = '',
    phone = '',
    mobile = '',
    socialMedia = SocialMediaHelper.init(),
  ): ParticipantContactInformation {
    return {email, mobile, phone, socialMedia};
  }

  public static initApp(settings = ParticipantHelper.initAppSettings()): ParticipantApp {
    return {settings};
  }

  public static initAppSettings(chat = ParticipantHelper.initAppChatSettings()): ParticipantAppSettings {
    return {chat};
  }

  public static initAppChatSettings(enabled: boolean = null): ParticipantAppChatSettings {
    return {enabled};
  }

  public static getIdentificationKey(importSystem: EventParticipantImportSystem): keyof UserExternalIds {
    switch (importSystem) {
      case EventParticipantImportSystem.AMADEUS:
        return 'amadeusId';

      case EventParticipantImportSystem.COLADA:
        return 'coladaId';

      case EventParticipantImportSystem.ARMIDA:
        return 'armidaId';

      default:
        throw new Error(`Event Participant-Import-System "${String(importSystem)}" not configured!`);
    }
  }
}
