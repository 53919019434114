import {ImageHelper} from '../../helpers/image.helper';
import {Image} from '../../interfaces/image.interface';
import {Identity} from '../identity.interface';
import {RoomDocumentMetaWithDate} from './room-document.interface';

export class RoomMetaModel implements RoomDocumentMetaWithDate, Identity {
  public constructor(
    public id: string = '',
    public eventId: string = '',
    public image: Image = ImageHelper.init(),
    public updatedAt: Date = new Date(),
  ) {}
}
