import {Locale} from '../../interfaces/environment.interface';
import {Image} from '../../interfaces/image.interface';
import {LocaleEntityType} from '../locale-entity.type';
import {LocaleSessionEntity} from './locale-session.entity';
import {MetadataSessionEntity} from './metadata-session.entity';

export class SessionLegacyEntity {
  public constructor(
    private id: string = '',
    private metadata: MetadataSessionEntity,
    private localizedData: LocaleEntityType<LocaleSessionEntity>,
  ) {}

  public getMetadataEntity(): MetadataSessionEntity {
    return this.metadata;
  }

  public getLocalizedContentEntity(locale: Locale = Locale.DE): LocaleSessionEntity {
    return this.localizedData[locale];
  }

  public getId(): string {
    return this.id;
  }

  public getLanguage(): string {
    return this.metadata.language;
  }

  public getAmadeusId(): string {
    return this.metadata.amadeusId;
  }

  public getEventReference(): string {
    return this.metadata.eventReference;
  }

  public getStart(): Date {
    return this.metadata.start;
  }

  public getEnd(): Date {
    return this.metadata.end;
  }

  public getSpeakers(): Array<string> {
    return this.metadata.speakers;
  }

  public getLocation(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].location;
  }

  public getDescription(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].description;
  }

  public getDescriptionMarkdown(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].descriptionMarkdown;
  }

  public getName(locale: Locale = Locale.DE): string {
    return this.localizedData[locale].name;
  }

  public getImage(): Image {
    return this.metadata.image;
  }

  public isOptional(): boolean {
    return this.metadata.isOptional;
  }

  public isPrivate(): boolean {
    return this.metadata.isPrivate;
  }

  public isAskingAllowed(): boolean {
    return this.metadata.isAskingAllowed;
  }

  public isQuestionRoundClosed(): boolean {
    return this.metadata.isQuestionRoundClosed;
  }

  public hideSpeakersInList(): boolean {
    return this.metadata.hideSpeakersInList;
  }

  public getType(): number {
    return this.metadata.type;
  }

  public getRoomId(): string {
    return this.metadata.roomId;
  }

  public getAwardFinalists(): string[] {
    return this.metadata.awardFinalists;
  }

  public getLocaleAward(locale: Locale = Locale.DE) {
    return this.localizedData[locale].award;
  }

  public isRatingAllowed(): boolean {
    return this.metadata.isRatingAllowed;
  }

  public isRatingRoundClosed(): boolean {
    return this.metadata.isRatingRoundClosed;
  }

  public getColadaId(): string {
    return this.metadata.coladaId;
  }

  public getArmidaId(): string {
    return this.metadata.armidaId;
  }
}
