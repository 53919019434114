import {Identity} from '../identity.interface';
import {SectorDocumentMetaWithDate} from './sector-document.interface';

export class SectorMetaModel implements SectorDocumentMetaWithDate, Identity {
  public constructor(
    public id: string = '',
    public eventId: string = '',
    public externalId: string = '',
    public color: string = '',
    public updatedAt: Date = new Date(),
  ) {}
}
