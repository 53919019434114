import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentReference,
  FieldPath,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {combineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {EventParticipantImportMode, EventParticipantImportSystem} from '../entities/event/event-document.interface';
import {EventLegacyEntity} from '../entities/event-legacy/event.legacy.entity';
import {LocaleEventEntity} from '../entities/event-legacy/locale-event.entity';
import {LocalizedEventEntity} from '../entities/event-legacy/localized-event.entity';
import {MetadataEventEntity} from '../entities/event-legacy/metadata-event.entity';
import {EventPerformanceEntity} from '../entities/event-performance-legacy/event-performance.entity';
import {LocalizedEventTypeEntity} from '../entities/event-type-legacy/localized-event-type.entity';
import {LocaleEntityType} from '../entities/locale-entity.type';
import {UserEntity} from '../entities/user/user.entity';
import {EventHelper} from '../helpers/event.helper';
import {ImageHelper} from '../helpers/image.helper';
import {SocialMediaHelper} from '../helpers/social-media.helper';
import {Locale, LOCALES} from '../interfaces/environment.interface';
import {EventAndSessions, EventsAndSessions} from '../interfaces/event-and-sessions.interface';
import {AbstractRepository} from './abstract.repository';
import {EventTypeLegacyRepository} from './event-type.legacy.repository';
import {SessionLegacyRepository} from './session.legacy.repository';

@Injectable({providedIn: 'root'})
export class EventLegacyRepository extends AbstractRepository {
  public constructor(
    angularFirestore: AngularFirestore,
    private sessionRepository: SessionLegacyRepository,
    private eventTypeRepository: EventTypeLegacyRepository,
  ) {
    super(angularFirestore, 'events');
    this.sessionRepository = sessionRepository;
  }

  /**
   * Save an event
   */
  public async saveEvent(metadataEvent: MetadataEventEntity, localizedDataArray: LocaleEventEntity[]): Promise<DocumentReference> {
    const eventsCollection = await this.angularFirestore.collection(this.collectionName);
    const i18nCollection = await this.angularFirestore.collection('i18n');

    const newDocument = eventsCollection.doc();

    const i18nPromises = localizedDataArray.map((localizedData) =>
      i18nCollection.doc(localizedData.locale).collection(this.collectionName).doc(newDocument.ref.id).set({
        event: newDocument.ref.path,
        name: localizedData.name,
        description: localizedData.description,
        descriptionMarkdown: localizedData.descriptionMarkdown,
        languages: localizedData.languages,
        location: localizedData.location,
        participants: localizedData.participants,
        price: localizedData.price,
        registrationLabel: localizedData.registrationLabel,
        registrationLink: localizedData.registrationLink,
        speakers: localizedData.speakers,
        additionalLink: localizedData.additionalLink,
        additionalLabel: localizedData.additionalLabel,
        locationLink: localizedData.locationLink,
        award: localizedData.award,
      }),
    );

    return Promise.all(i18nPromises)
      .then(() =>
        newDocument.set(
          {
            amadeusId: metadataEvent.amadeusId,
            hashtag: metadataEvent.hashtag,
            status: metadataEvent.status,
            wifiPw: metadataEvent.wifiPw,
            wifiSsid: metadataEvent.wifiSsid,
            times: metadataEvent.times,
            eventType: metadataEvent.eventType,
            background: metadataEvent.background,
            backgroundOverlay: metadataEvent.backgroundOverlay,
            backgroundColor: metadataEvent.backgroundColor,
            backgroundOppositeColor: metadataEvent.backgroundOppositeColor,
            logo: metadataEvent.logo,
            accentColor: metadataEvent.accentColor,
            accentOppositeColor: metadataEvent.accentOppositeColor,
            newsUrl: metadataEvent.newsUrl,
            bottomNavigationLabel: metadataEvent.bottomNavigationLabel,
            votingEnabled: metadataEvent.isVotingEnabled,
            questionEnabled: metadataEvent.isQuestionEnabled,
            supportedLanguages: metadataEvent.supportedLanguages,
            fallbackLanguage: metadataEvent.fallbackLanguage,
            award: metadataEvent.award,
            livestreamUrl: metadataEvent.livestreamUrl,
            isLivestreamEnabled: metadataEvent.isLivestreamEnabled,
            isLivestreamPublic: metadataEvent.isLivestreamPublic,
            teaser: metadataEvent.teaser,
            participantImportMode: metadataEvent.participantImportMode,
            isParticipantImportAllowed: metadataEvent.isParticipantImportAllowed,
            socialMedia: metadataEvent.socialMedia,
            coladaId: metadataEvent.coladaId,
            participantImportSystem: metadataEvent.participantImportSystem,
            nzzConnectCmsId: metadataEvent.nzzConnectCmsId,
          },
          {merge: true},
        ),
      )
      .then(() => newDocument.ref);
  }

  /**
   * Get all events by locale
   */
  public getEventsByLocale(locale: Locale = Locale.DE): Observable<LocalizedEventEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(this.angularFirestore.collection(this.collectionName)),
      this.collectionName,
      'event',
      locale,
    ).pipe(
      map((events) =>
        events
          .map((event) => this.createEntityFromResponseWithLocale(event))
          .sort((eventA: LocalizedEventEntity, eventB: LocalizedEventEntity) => this.sortDescByStartTime(eventA, eventB)),
      ),
    );
  }

  public getEventsByLocaleAndStatus(locale: Locale = Locale.DE, statuses: string[]): Observable<LocalizedEventEntity[]> {
    const events$ = this.filterCollectionByMultipleValues('status', '==', statuses);

    const localizedEvents$ = this.getDocumentsFromCollectionWithLocalizedContent(events$, this.collectionName, 'event', locale);

    return localizedEvents$.pipe(map((events) => events.map((event) => this.createEntityFromResponseWithLocale(event))));
  }

  public getCurrentEvents(locale: Locale = Locale.DE): Observable<EventPerformanceEntity[]> {
    return this.getEventsByLocaleAndStatus(locale, ['active', 'preannouncement']).pipe(
      switchMap((events: LocalizedEventEntity[]) => this.mapToEventPerformance(events, locale)),
      map((performances: EventPerformanceEntity[]) =>
        performances.sort((performanceA: EventPerformanceEntity, performanceB: EventPerformanceEntity) =>
          this.sortAscByStartTime(performanceA.event, performanceB.event),
        ),
      ),
    );
  }

  /**
   * Gets all archived events with it's event type
   *
   */
  public getArchivedEventsByLocale(locale: Locale = Locale.DE): Observable<EventPerformanceEntity[]> {
    return this.getEventsByLocaleAndStatus(locale, ['archive']).pipe(
      switchMap((events: LocalizedEventEntity[]) => this.mapToEventPerformance(events, locale)),
      map((performances: EventPerformanceEntity[]) =>
        performances.sort((performanceA: EventPerformanceEntity, performanceB: EventPerformanceEntity) =>
          this.sortDescByStartTime(performanceA.event, performanceB.event),
        ),
      ),
    );
  }

  /**
   * Get an event by key with localized content
   */
  public getEventByKeyWithLocalizedContent(key: string, locale: Locale = Locale.DE): Observable<LocalizedEventEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'event',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  /**
   * Get last past event by event type
   */
  public getLastPastEventByEventType(eventTypeId: string, locale: Locale = Locale.DE): Observable<LocalizedEventEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) => ref.where('eventType', '==', eventTypeId)),
      ),
      this.collectionName,
      'event',
      locale,
    ).pipe(
      map(
        (events) =>
          events
            .map((event) => this.createEntityFromResponseWithLocale(event))
            .map((eventEntity: LocalizedEventEntity) => {
              // Return all past events
              if (!eventEntity.isToday && !eventEntity.isInFuture && (eventEntity.isActive || eventEntity.isArchived)) {
                return eventEntity;
              }
            })
            .sort((eventA: LocalizedEventEntity, eventB: LocalizedEventEntity) => this.sortDescByStartTime(eventA, eventB))[0],
      ),
    );
  }

  /**
   * Get an event by key with localized contents
   */
  public getEventByKeyWithLocalizedContents(key: string, locales: any[]): Observable<any> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'event',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  /**
   * Get all events with his sessions
   */
  public getEventsWithSessionsByLocale(locale: Locale = Locale.DE): Observable<EventsAndSessions> {
    return this.getEventsByLocale(locale).pipe(
      switchMap((events) => {
        const eventsWithSessions: Observable<EventAndSessions>[] = [];
        for (const event of events) {
          const items = this.sessionRepository.getSessionsByEventAndLocale(event.id, locale).pipe(
            map(
              (sessions) =>
                // Map event to sessions
                ({
                  event,
                  sessions,
                } as EventAndSessions),
            ),
          );
          eventsWithSessions.push(items);
        }

        // Combine all observables in this array and return it as one observable array
        return combineLatest([...eventsWithSessions]);
      }),
    );
  }

  /**
   * Get the next event for given participant
   * returns next event for which participant has a ticket or just next event if participant doesn't have any tickets.
   *
   * Where is this method being used? Webstorm doesn't find any usages except for test files. Can it be safely removed?
   */
  public getNextEventForParticipant(participant: UserEntity, locale: Locale = Locale.DE): Observable<any> {
    return this.getEventsByLocale(locale).pipe(
      map((events) => {
        const futureEventsSorted = this.filterOutPastEventsAndSortByStartTime(events);

        if (!futureEventsSorted) {
          return null;
        }

        const futureEventWithTicket = futureEventsSorted.find(
          (event: LocalizedEventEntity) => event.isInFuture && participant.eventIds.find((id) => id === event.id),
        );

        if (futureEventWithTicket) {
          return futureEventWithTicket;
        }
        return futureEventsSorted[0];
      }),
    );
  }

  public async updateEvent(eventEntity: EventLegacyEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, eventEntity.getLocalizedContentEntity(localeKey).id, {
        name: eventEntity.getName(localeKey),
        description: eventEntity.getDescription(localeKey),
        descriptionMarkdown: eventEntity.getDescriptionMarkdown(localeKey),
        languages: eventEntity.getLanguages(localeKey),
        location: eventEntity.getLocation(localeKey),
        participants: eventEntity.getParticipants(localeKey),
        price: eventEntity.getPrice(localeKey),
        registrationLabel: eventEntity.getRegistrationLabel(localeKey),
        registrationLink: eventEntity.getRegistrationLink(localeKey),
        speakers: eventEntity.getSpeakers(localeKey),
        additionalLink: eventEntity.getAdditionalLink(localeKey),
        additionalLabel: eventEntity.getAdditionalLabel(localeKey),
        locationLink: eventEntity.getLocationLink(localeKey),
        award: eventEntity.getLocaleAward(localeKey),
      });
    }

    return this.updateDocument(eventEntity.getId(), {
      amadeusId: eventEntity.getAmadeusId(),
      hashtag: eventEntity.getHashtag(),
      status: eventEntity.getStatus(),
      wifiPw: eventEntity.getWifiPw(),
      wifiSsid: eventEntity.getWifiSsid(),
      times: eventEntity.getTimes(),
      eventType: eventEntity.getEventType(),
      background: eventEntity.getBackground(),
      backgroundOverlay: eventEntity.getBackgroundOverlay(),
      logo: eventEntity.getLogo(),
      backgroundColor: eventEntity.getBackgroundColor(),
      backgroundOppositeColor: eventEntity.getBackgroundOppositeColor(),
      accentColor: eventEntity.getAccentColor(),
      accentOppositeColor: eventEntity.getAccentOppositeColor(),
      newsUrl: eventEntity.getNewsUrl(),
      updatedAt: new Date(),
      bottomNavigationLabel: eventEntity.getBottomNavigationLabel(),
      votingEnabled: eventEntity.isVotingEnabled,
      questionEnabled: eventEntity.isQuestionEnabled,
      supportedLanguages: eventEntity.getSupportedLanguages(),
      fallbackLanguage: eventEntity.getFallbackLanguage(),
      award: eventEntity.getMetadataAward(),
      livestreamUrl: eventEntity.getLivestreamUrl(),
      isLivestreamEnabled: eventEntity.isLivestreamEnabled,
      isLivestreamPublic: eventEntity.isLivestreamPublic,
      teaser: eventEntity.getTeaser(),
      participantImportMode: eventEntity.getParticipantImportMode(),
      isParticipantImportAllowed: eventEntity.isParticipantImportAllowed,
      socialMedia: eventEntity.getSocialMedia(),
      coladaId: eventEntity.getColadaId(),
      participantImportSystem: eventEntity.getParticipantImportSystem(),
      nzzConnectCmsId: eventEntity.getNzzConnectCmsId(),
    });
  }

  /**
   * Create an event entity with all locales
   */
  public createEntityFromResponseWithLocales(response): EventLegacyEntity {
    const localizedEntities = {} as LocaleEntityType<LocaleEventEntity>;
    for (const key of Object.keys(response.localized)) {
      const descriptionMarkdown =
        response.localized[key].descriptionMarkdown === undefined
          ? response.localized[key].description
          : response.localized[key].descriptionMarkdown;

      localizedEntities[key] = new LocaleEventEntity(
        response.localized[key].id,
        response.localized[key].key,
        response.localized[key].name,
        response.localized[key].location,
        response.localized[key].description,
        descriptionMarkdown,
        response.localized[key].languages,
        response.localized[key].participants,
        response.localized[key].price,
        response.localized[key].registrationLabel,
        response.localized[key].registrationLink,
        response.localized[key].speakers,
        response.localized[key].additionalLink,
        response.localized[key].additionalLabel,
        response.localized[key].locationLink,
        response.localized[key].award || EventHelper.initLocaleEventAward(),
      );
    }

    const metadataEventEntity = this.getMetaEntityFromResponse(response);

    return new EventLegacyEntity(response.id, metadataEventEntity, localizedEntities);
  }

  /**
   * Maps events to eventTypes to create EventPerformances
   */
  private mapToEventPerformance(events: LocalizedEventEntity[], locale: Locale): Observable<EventPerformanceEntity[]> {
    return this.eventTypeRepository.getEventTypesByLocale(locale).pipe(
      map((allEventTypes: LocalizedEventTypeEntity[]) =>
        events.map(
          (event: LocalizedEventEntity) =>
            new EventPerformanceEntity(
              allEventTypes.find((eventType) => event.eventType === eventType.id),
              event,
            ),
        ),
      ),
    );
  }

  /**
   * Makes for each value to filter by an own query and joins the result to a single stream
   */
  private filterCollectionByMultipleValues(fieldPath: string | FieldPath, opStr: firebase.firestore.WhereFilterOp, values: any[]) {
    const documentCollections: Observable<any>[] = [];
    for (const value of values) {
      const documentCollection: AngularFirestoreCollection = this.angularFirestore.collection(
        this.collectionName,
        (ref: CollectionReference) => ref.where(fieldPath, opStr, value),
      );

      documentCollections.push(this.getDocumentsAsObservable(documentCollection));
    }

    const documents$: Observable<any> = combineLatest(documentCollections).pipe(map((collections) => [].concat(...collections)));

    return documents$;
  }

  private sortDescByStartTime(eventA: LocalizedEventEntity, eventB: LocalizedEventEntity): number {
    return eventB.getMetadataEntity().startDate.getTime() - eventA.getMetadataEntity().startDate.getTime();
  }

  private sortAscByStartTime(eventA: LocalizedEventEntity, eventB: LocalizedEventEntity): number {
    return eventA.getMetadataEntity().startDate.getTime() - eventB.getMetadataEntity().startDate.getTime();
  }

  private getMetaEntityFromResponse(response): MetadataEventEntity {
    const times = response.times.map((time) => ({
      start: time.start.toDate(),
      end: time.end.toDate(),
    }));

    return new MetadataEventEntity(
      response.amadeusId,
      times,
      response.wifiSsid,
      response.wifiPw,
      response.hashtag,
      response.status,
      response.eventType,
      response.background || ImageHelper.init(),
      response.backgroundOverlay || ImageHelper.init(),
      response.logo || ImageHelper.init(),
      response.backgroundColor,
      response.backgroundOppositeColor || '#ffffff',
      response.accentColor,
      response.accentOppositeColor !== undefined ? response.accentOppositeColor : '#ffffff',
      response.newsUrl,
      response.bottomNavigationLabel || '',
      response.votingEnabled === undefined ? true : response.votingEnabled,
      response.questionEnabled === undefined ? true : response.questionEnabled,
      response.supportedLanguages || LOCALES,
      response.fallbackLanguage || Locale.DE,
      response.award || EventHelper.initMetadataAward(),
      response.livestreamUrl || '',
      response.isLivestreamEnabled || false,
      response.isLivestreamPublic || false,
      response.teaser || ImageHelper.init(),
      response.participantImportMode || EventParticipantImportMode.MANUAL,
      response.isParticipantImportAllowed || false,
      response.socialMedia || SocialMediaHelper.init(),
      response.coladaId || '',
      response.participantImportSystem || EventParticipantImportSystem.AMADEUS,
      response.nzzConnectCmsId || '',
    );
  }

  /**
   * Create an localized event entity
   */
  private createEntityFromResponseWithLocale(document: any, locale: Locale = Locale.DE): LocalizedEventEntity {
    const localizedContent = new LocaleEventEntity(
      document.id,
      locale,
      document.name,
      document.location,
      document.description,
      document.descriptionMarkdown === undefined ? document.description : document.descriptionMarkdown,
      document.languages,
      document.participants,
      document.price,
      document.registrationLabel,
      document.registrationLink,
      document.speakers,
      document.additionalLink,
      document.additionalLabel,
      document.locationLink,
      document.award || EventHelper.initLocaleEventAward(),
    );

    const metadataEventEntity = this.getMetaEntityFromResponse(document);

    return new LocalizedEventEntity(document.id, metadataEventEntity, localizedContent);
  }

  private filterOutPastEventsAndSortByStartTime(events: LocalizedEventEntity[]): LocalizedEventEntity[] {
    return events
      .filter((event: LocalizedEventEntity) => event.isInFuture || event.isToday)
      .sort((left: LocalizedEventEntity, right: LocalizedEventEntity) => {
        if (left.times.length === 0) {
          return 1;
        }
        if (right.times.length === 0) {
          return -1;
        }

        return this.sortAscByStartTime(left, right);
      });
  }
}
