import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {BannerLegacyEntity} from '../entities/banner-legacy/banner.legacy.entity';
import {LocaleBannerEntity} from '../entities/banner-legacy/locale-banner.entity';
import {LocalizedBannerEntity} from '../entities/banner-legacy/localized-banner.entity';
import {MetadataBannerEntity} from '../entities/banner-legacy/metadata-banner.entity';
import {LocaleEntityType} from '../entities/locale-entity.type';
import {ImageHelper} from '../helpers/image.helper';
import {Locale} from '../interfaces/environment.interface';
import {AbstractRepository} from './abstract.repository';

@Injectable({providedIn: 'root'})
export class BannerLegacyRepository extends AbstractRepository {
  public constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore, 'banners');
  }

  public async saveBanner(metadata: MetadataBannerEntity, localizedDataArray: LocaleBannerEntity[]): Promise<any> {
    const bannerCollection = await this.angularFirestore.collection(this.collectionName);
    const i18nCollection = await this.angularFirestore.collection('i18n');

    const newDocument = bannerCollection.doc();

    const i18nPromises = localizedDataArray.map((localizedData) =>
      i18nCollection.doc(localizedData.locale).collection(this.collectionName).doc(newDocument.ref.id).set({
        banner: newDocument.ref.path,
        image: localizedData.image,
        url: localizedData.url,
      }),
    );

    return Promise.all(i18nPromises)
      .then(() =>
        newDocument.set(
          {
            eventReference: metadata.eventReference,
            actionType: metadata.actionType,
            actionEntityId: metadata.actionEntityId,
          },
          {merge: true},
        ),
      )
      .then(() => newDocument.ref);
  }

  /**
   * Get banners as array
   */
  public getBannersByEventAndLocale(eventId: string, locale: Locale = Locale.DE): Observable<LocalizedBannerEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) => ref.where('eventReference', '==', 'event/' + eventId)),
      ),
      this.collectionName,
      'banner',
      locale,
    ).pipe(map((documents) => documents.map((document) => this.createEntityFromResponseWithLocale(document))));
  }

  /**
   * Return banners by event with localized contents
   */
  public getBannersByEventWithLocalizedContent(eventId: string, locales: any[]): Observable<BannerLegacyEntity[]> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentsAsObservable(
        this.angularFirestore.collection(this.collectionName, (ref) => ref.where('eventReference', '==', 'event/' + eventId)),
      ),
      this.collectionName,
      'banner',
      locales,
    ).pipe(map((documents) => documents.map((document) => this.createEntityFromResponseWithLocales(document))));
  }

  /**
   * Return banner by document key with localized contents
   */
  public getBannerByKeyWithLocalizedContent(key: string, locales: any[]): Observable<BannerLegacyEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'banner',
      locales,
    ).pipe(map((response) => this.createEntityFromResponseWithLocales(response)));
  }

  /**
   * Return banner by document key with localized contents
   */
  public getBannerByKeyAndLocale(key: string, locale: Locale = Locale.DE): Observable<LocalizedBannerEntity> {
    return this.getDocumentsFromCollectionWithLocalizedContent(
      this.getDocumentAsObservable(this.angularFirestore.collection(this.collectionName).doc(key)),
      this.collectionName,
      'banner',
      locale,
    ).pipe(map((response) => this.createEntityFromResponseWithLocale(response)));
  }

  public async updateBanner(bannerEntity: BannerLegacyEntity, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.updateI18nDocument(this.collectionName, localeKey, bannerEntity.getLocalizedContentEntity(localeKey).id, {
        image: bannerEntity.getImage(localeKey),
        url: bannerEntity.getUrl(localeKey),
      });
    }

    return this.updateDocument(bannerEntity.getId(), {
      eventReference: bannerEntity.getEventReference(),
      actionType: bannerEntity.getActionType(),
      actionEntityId: bannerEntity.getActionEntityId(),
      updatedAt: new Date(),
    });
  }

  /**
   * Deletes a banner.
   */
  public async deleteBanner(bannerId: string, locales: any[]): Promise<any> {
    for (const locale of locales) {
      const localeKey = locale.key;
      await this.deleteI18nDocument(this.collectionName, bannerId, 'banner', localeKey);
    }

    return this.deleteDocument(this.collectionName, bannerId);
  }

  /**
   * Create entity from response with locale
   */
  private createEntityFromResponseWithLocale(response, locale: Locale = Locale.DE): LocalizedBannerEntity {
    const localizedContent = new LocaleBannerEntity(response.id, locale, response.image || ImageHelper.init(), response.url);

    const metadataEntity = new MetadataBannerEntity(response.eventReference, response.actionType, response.actionEntityId);

    return new LocalizedBannerEntity(response.id, metadataEntity, localizedContent);
  }

  /**
   * Create entity from response
   */
  private createEntityFromResponseWithLocales(response): BannerLegacyEntity {
    const localizedEntities = {} as LocaleEntityType<LocaleBannerEntity>;

    for (const key of Object.keys(response.localized) as Locale[]) {
      localizedEntities[key] = new LocaleBannerEntity(
        response.localized[key] ? response.localized[key].id : '',
        key,
        response.localized[key] ? response.localized[key].image : ImageHelper.init(),
        response.localized[key] ? response.localized[key].url || '' : '',
      );
    }

    const metadataEntity = new MetadataBannerEntity(response.eventReference, response.actionType, response.actionEntityId);

    return new BannerLegacyEntity(response.id, metadataEntity, localizedEntities);
  }
}
