import {SocialMedia} from '../../interfaces/social-media.interface';
import {ClassifiedsModels} from '../classifieds/classifieds.model';
import {InterestsModels} from '../interests/interests.model';
import {
  UserClassifieds,
  UserClassifiedsIdsGroupedByType,
  UserClassifiedsType,
  UserEventParticipation,
  UserEventParticipationInfo,
  UserEventParticipationType,
  UserInterests,
  UserInterestsArray,
  UserProfileImage,
} from './user.interface';

export type MatchType = 'match' | 'unmatched';
export type UserClassifiedsIdsGroupedByTypeAndMatch = Record<UserClassifiedsType, Record<MatchType, string[]>>;
export type ClassifiedsMatchTypeMapping = Record<UserClassifiedsType, UserClassifiedsType>;
export type UserInterestIdsGroupedByMatch = Record<MatchType, string[]>;

export abstract class BaseUser<CONTACT_INFORMATION extends {socialMedia: SocialMedia}> {
  public static CLASSIFIEDS_MATCH_TYPE_MAPPING: ClassifiedsMatchTypeMapping = {search: 'offer', offer: 'search'};
  public title: string;
  public firstname: string;
  public lastname: string;
  public profileImage: UserProfileImage;
  public contactInformation: CONTACT_INFORMATION;
  public eventParticipation: UserEventParticipation;
  public classifieds: UserClassifieds;
  public interests: UserInterests;

  public get eventIds(): string[] {
    return Object.keys(this.eventParticipation);
  }

  public get sessionIds(): string[] {
    return Object.values(this.eventParticipation)
      .map((eventParticipation) => Object.keys(eventParticipation?.sessions || {}))
      .reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
  }

  public get hasSocialMedia(): boolean {
    return Object.values(this.contactInformation.socialMedia).some((socialMedia) => socialMedia !== null && socialMedia !== '');
  }

  public get hasProfileImage(): boolean {
    return !!this.profileImage.original.downloadUrl;
  }

  public get displayName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  public get displayNameForList(): string {
    return `${this.lastname} ${this.firstname}`;
  }

  public get classifiedsGroupedByType(): UserClassifiedsIdsGroupedByType {
    return Object.entries(this.classifieds).reduce<UserClassifiedsIdsGroupedByType>(
      (classifiedsIdsGroupedByType, [id, classifiedsTypeStatus]) =>
        Object.keys(classifiedsTypeStatus).reduce<UserClassifiedsIdsGroupedByType>(
          (userClassifiedsIdsGroupedByType, type) => ({
            ...userClassifiedsIdsGroupedByType,
            [type]: [...userClassifiedsIdsGroupedByType[type], id],
          }),
          classifiedsIdsGroupedByType,
        ),
      {search: [], offer: []},
    );
  }

  public getSectorIdForEvent(eventId: string): string {
    return this.getEventParticipationInfo(eventId, UserEventParticipationType.SECTOR_ID);
  }

  public hasEventWithId(eventId: string): boolean {
    return Object.prototype.hasOwnProperty.call(this.eventParticipation, eventId);
  }

  public hasSessionWithId(sessionId: string): boolean {
    return this.eventIds.some((eventId) =>
      Object.keys(this.eventParticipation[eventId]?.sessions || {}).some((tmpSessionId) => tmpSessionId === sessionId),
    );
  }

  public hasValidClassifieds(models: ClassifiedsModels): boolean {
    return !!Object.keys(this.classifieds).filter((id) => models.find((model) => model.id === id)).length;
  }

  public hasValidInterests(models: InterestsModels): boolean {
    return !!this.interestsAsArray.filter((id) => models.find((model) => model.id === id)).length;
  }

  public getClassifiedsGroupedByTypeAndMatch(
    classifiedsIdsGroupedByType: UserClassifiedsIdsGroupedByType | null,
  ): UserClassifiedsIdsGroupedByTypeAndMatch {
    return Object.entries(this.classifiedsGroupedByType).reduce<UserClassifiedsIdsGroupedByTypeAndMatch>(
      (classifiedsGroupedByTypeAndMatch, [type, ids]) =>
        ids.reduce<UserClassifiedsIdsGroupedByTypeAndMatch>((userClassifiedsGroupedByTypeAndMatch, id) => {
          const found = classifiedsIdsGroupedByType?.[BaseUser.CLASSIFIEDS_MATCH_TYPE_MAPPING[type]]?.find((tmpId) => tmpId === id);
          const matchType: MatchType = found ? 'match' : 'unmatched';

          return {
            ...userClassifiedsGroupedByTypeAndMatch,
            [type]: {
              ...userClassifiedsGroupedByTypeAndMatch[type],
              [matchType]: [...userClassifiedsGroupedByTypeAndMatch[type][matchType], id],
            },
          };
        }, classifiedsGroupedByTypeAndMatch),
      {search: {match: [], unmatched: []}, offer: {match: [], unmatched: []}},
    );
  }

  public getInterestsGroupedByMatch(interestsArray: UserInterestsArray | null): UserInterestIdsGroupedByMatch {
    return this.interestsAsArray.reduce<UserInterestIdsGroupedByMatch>(
      (interestsGroupedByMatch, id) => {
        const found = interestsArray.some((tmpId) => tmpId === id);
        const matchType: MatchType = found ? 'match' : 'unmatched';

        return {
          ...interestsGroupedByMatch,
          [matchType]: [...interestsGroupedByMatch[matchType], id],
        };
      },
      {match: [], unmatched: []},
    );
  }

  public get interestsAsArray(): UserInterestsArray {
    return Object.keys(this.interests);
  }

  protected getEventParticipationInfo(eventId: string): UserEventParticipationInfo;
  protected getEventParticipationInfo<KEY extends UserEventParticipationType>(eventId: string, key: KEY): UserEventParticipationInfo[KEY];
  protected getEventParticipationInfo<KEY extends UserEventParticipationType>(eventId: string, key?: KEY) {
    if (key) {
      return this.eventParticipation?.[eventId]?.[key] || (key === UserEventParticipationType.SESSIONS ? {} : null);
    }

    return this.eventParticipation?.[eventId] || {};
  }

  public getCompanyForEvent(eventId: string): string {
    return this.getEventParticipationInfo(eventId, UserEventParticipationType.COMPANY);
  }

  public getJobForEvent(eventId: string): string {
    return this.getEventParticipationInfo(eventId, UserEventParticipationType.JOB);
  }
}
